import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, getFormValues } from 'redux-form';

import { getIntervaloList, init } from './intervaloAdaptativoActions';
import Select from '../common/form/selectSemGrid';
// import { toastr } from 'react-redux-toastr';

import ReactTableGT2A from '../common/grid/gridReactTable';
import configLocal from '../configuracao/configLocal';

const InputValidacao = require('../common/form/inputValidacao.js');

class IntervaloAdaptativoList extends Component {

    constructor() {
        super();

        this.renderEditable = this.renderEditable.bind(this);
        this.carregaDetectores = this.carregaDetectores.bind(this);

        this.nIntervId = 0;
        this.tempoRealId = 0;
        this.segundos = 0.0;
        this.tempoIntervalo = [];
        this.coresFase = [];
        this.pngFase = [];
        this.countTempo = 0;
        this.corIdAtual = 0;
        this.segundoAtual = 0;
        this.intervaloAnterior = 0;
        this.intervaloTempoReal = 0;

        this.state = {
            show: false,
            loop: 0,
            disabledbtn: false,
            disabledbtnSimula: false,
            disabledbtnTempoReal: false,
            disabledbtnplano: false,
            intervalos: []
        };

    }

    componentDidMount() {
        this.setState({ intervalos: this.props['data-intervalos'] });
    }

    carregaDetectores(classOptionNDHidden){
        let list = [];
        let dataIntervalo = this.props.valores.intervalo || [];
        
        for (let index = 5; index <= 12; index++) {

            list[index] = {id:index, desc:`L${index}`, class:''};

            for (const key in dataIntervalo) {
                if(['D1', 'D2', 'D3', 'D4'].indexOf(dataIntervalo[key].descricao) >= 0 ){
                    let linha = dataIntervalo[key];
                    for (const key_l in linha) {
                        if(parseInt(linha[key_l]) === index){
                            list[index].class = 'option_hidden';
                        }
                    }
                }
            }
        }

        return list.map(detector => (
            <option key={detector.id} value={detector.id} className={classOptionNDHidden+' '+detector.class}>L{detector.id-4}&nbsp;&nbsp;=&nbsp;&nbsp;Laço {detector.id-4}</option>
        ))
    }

    renderEditable(cellInfo) {

        let valorAtual = this.state.intervalos[cellInfo.index][cellInfo.column.id];
        let modoOperacao = ['P', 'P', 'S', 'A', 'SA'];// ser vir zero é tem que atualizar a trigger para criar como 1
        let modoDesabled = '';
        let classOptionHidden = '';
        let classOptionNDHidden = '';
        let SLEditable = true;
        let col_id = 0;
        let classModValid = '';
        let classNDValid = '';

        if (cellInfo.original.descricao === 'Mod') {// carrega a linha referente ao Mod

            let classeMod = ['sel-nenhum', 'mod-p', 'mod-s', 'mod-a', 'mod-sa'];

            classModValid = `class_mod_${cellInfo.column.id}`;

            return (
                <div
                    style={{

                        textAlign: 'center'
                    }}
                    suppressContentEditableWarning
                    className={`input-gridPlano select-gridPlano-mod class_borda_${cellInfo.column.id} ${classeMod[valorAtual]} ${classModValid}`}
                    // className={`input-gridPlano class_borda_${cellInfo.column.id} mod-p`}
                    dangerouslySetInnerHTML={{
                        __html: modoOperacao[this.state.intervalos[cellInfo.index][cellInfo.column.id]]
                    }}
                />
            )
        } else {
            if (['D1', 'D2', 'D3', 'D4'].indexOf(cellInfo.original.descricao) >= 0) {// se for ND deve ferificar se é atuado para gerar o combobox

                // se não for atuado, não mostra nada no select ND
                if (['1', '3'].indexOf(this.state.intervalos[0][cellInfo.column.id]) < 0) {
                    classOptionNDHidden = 'option_hidden';
                    // const data = this.state.intervalos;
                    // data[cellInfo.index][cellInfo.column.id] = 0;
                    // this.setState({ data });
                } else {
                    classOptionNDHidden = '';
                }

                classNDValid = `class_nd_${cellInfo.column.id}`;

                return (
                    <Select
                        id={`select_numdetector_${cellInfo.column.id}`}
                        className={`select-gridPlano select-gridPlano-nd class_borda_${cellInfo.column.id} ${classNDValid}`}
                        contentEditable
                        suppressContentEditableWarning
                        onChange={event => {

                            // if (validaPermissaoDisabled('CadAdaptativo', 'intervalo_nd_sl') === '') {
                            const data = this.state.intervalos;

                            if (data[cellInfo.index][cellInfo.column.id] != event.target.value) {
                                if (parseInt(event.target.value) > 0) {
                                    window.$(`.class_nd_${cellInfo.column.id}`).css('border', 'solid #cccccc 2px');
                                }

                                data[cellInfo.index][cellInfo.column.id] = event.target.value;
                                this.setState({ data });
                            }
                            // }

                        }}

                        // disabled={validaPermissaoDisabled('CadAdaptativo', 'intervalo_nd_sl')}
                        value={this.state.intervalos[cellInfo.index][cellInfo.column.id]}

                    >
                        <option value='0' className={classOptionNDHidden} ></option>
                        {this.carregaDetectores(classOptionNDHidden)}                        
                        {/*<option value='5' className={classOptionNDHidden}  >L1&nbsp;&nbsp;=&nbsp;&nbsp;Laço 1</option>
                        <option value='6' className={classOptionNDHidden}  >L2&nbsp;&nbsp;=&nbsp;&nbsp;Laço 2</option>
                        <option value='7' className={classOptionNDHidden}  >L3&nbsp;&nbsp;=&nbsp;&nbsp;Laço 3</option>
                        <option value='8' className={classOptionNDHidden}  >L4&nbsp;&nbsp;=&nbsp;&nbsp;Laço 4</option>
                        <option value='9' className={classOptionNDHidden}  >L5&nbsp;&nbsp;=&nbsp;&nbsp;Laço 5</option>
                        <option value='10' className={classOptionNDHidden} >L6&nbsp;&nbsp;=&nbsp;&nbsp;Laço 6</option>
                        <option value='11' className={classOptionNDHidden} >L7&nbsp;&nbsp;=&nbsp;&nbsp;Laço 7</option>
                        <option value='12' className={classOptionNDHidden} >L8&nbsp;&nbsp;=&nbsp;&nbsp;Laço 8</option> */}
                    </Select>
                );

            } else {
                if (['Fase'].indexOf(cellInfo.original.descricao) >= 0) {// se for ND deve ferificar se é atuado para gerar o combobox

                    if (['1', '3'].indexOf(this.state.intervalos[0][cellInfo.column.id]) < 0) {
                        classOptionNDHidden = 'option_hidden';
                        // const data = this.state.intervalos;
                        // data[cellInfo.index][cellInfo.column.id] = 0;
                        // this.setState({ data });
                    } else {
                        classOptionNDHidden = '';
                    }

                    classNDValid = `class_nd_${cellInfo.column.id}`;


                    return (
                        <Select
                            id={`select_numdetector_${cellInfo.column.id}`}
                            className={`select-gridPlano select-gridPlano-nd class_borda_${cellInfo.column.id} ${classNDValid}`}
                            contentEditable
                            suppressContentEditableWarning
                            onChange={event => {

                                // if (validaPermissaoDisabled('CadAdaptativo', 'intervalo_nd_sl') === '') {
                                const data = this.state.intervalos;

                                if (data[cellInfo.index][cellInfo.column.id] != event.target.value) {
                                    if (parseInt(event.target.value) > 0) {
                                        window.$(`.class_nd_${cellInfo.column.id}`).css('border', 'solid #cccccc 2px');
                                    }

                                    data[cellInfo.index][cellInfo.column.id] = event.target.value;
                                    this.setState({ data });
                                }
                                // }

                            }}

                            // disabled={validaPermissaoDisabled('CadAdaptativo', 'intervalo_nd_sl')}
                            value={this.state.intervalos[cellInfo.index][cellInfo.column.id]}

                        >
                            <option value='0' className={classOptionNDHidden} ></option>
                            <option value='1' className={classOptionNDHidden} >1</option>
                            <option value='2' className={classOptionNDHidden} >2</option>
                            <option value='3' className={classOptionNDHidden} >3</option>
                            <option value='4' className={classOptionNDHidden} >4</option>
                            <option value='5' className={classOptionNDHidden} >5</option>
                            <option value='6' className={classOptionNDHidden} >6</option>
                            <option value='7' className={classOptionNDHidden} >7</option>
                            <option value='8' className={classOptionNDHidden} >8</option>
                        </Select>
                    );

                }
            }

        }

        if (['TpN', 'TMin', 'TMax',].indexOf(cellInfo.original.descricao) >= 0) {

            let valorHTML = this.state.intervalos[cellInfo.index][cellInfo.column.id];
            let classTpNZero = '';
            let inputEnabled = '';

            // quando carrega remove os decimais se for P ou A
            // aqui carrega o tpn bloqueado só para visualizar
            if (cellInfo.original.descricao === 'TpN') {
                if (['1', '3'].indexOf(this.state.intervalos[0][cellInfo.column.id]) >= 0) {
                    valorHTML = parseFloat(this.state.intervalos[cellInfo.index][cellInfo.column.id]).toFixed(0);
                } else {
                    valorHTML = parseFloat(this.state.intervalos[cellInfo.index][cellInfo.column.id]).toFixed(1);
                }
                // é utilizado para pintar os campos com valor zerado
                classTpNZero = `class_tpn_${cellInfo.column.id}`;

                return (
                    <div
                        style={{

                            textAlign: 'center'
                        }}
                        suppressContentEditableWarning
                        className={`input-gridPlano class_borda_${cellInfo.column.id} ${classTpNZero} input-gridPlano-enabled`}
                        // className={`input-gridPlano class_borda_${cellInfo.column.id} mod-p`}

                        dangerouslySetInnerHTML={{
                            __html: valorHTML
                        }}
                    />
                )

            }

            SLEditable = true;

            // if (validaPermissaoReadOnly('CadAdaptativo', 'intervalo_tpn_tpm')) {// se readOnly for true, o editavel dever ser false
            //     SLEditable = false;
            //     inputEnabled = 'input-gridPlano-enabled';
            // }
            return (
                <div
                    style={{
                        backgroundColor: "#fafafa",
                        textAlign: 'center'
                    }}
                    contentEditable={SLEditable}
                    suppressContentEditableWarning
                    className={`input-gridPlano class_borda_${cellInfo.column.id} ${classTpNZero} ${inputEnabled}`}
                    onKeyDown={evento => { // aceita só números
                        return InputValidacao.validaSoNumeroInteiro(evento);
                        // // se for P ou A deve validar só inteiros
                        // if (cellInfo.original.descricao === 'TpN') {
                        //     if (['1', '3'].indexOf(this.state.intervalos[0][cellInfo.column.id]) >= 0) {
                        //         return InputValidacao.validaSoNumeroInteiro(evento);
                        //     }
                        // }// se não pode validar decimal
                        // return InputValidacao.validaSoNumeroDecimal(evento);                                

                    }}
                    onFocus={e => {
                        var cell = e.target;
                        var range, selection;
                        if (document.body.createTextRange) {
                            range = document.body.createTextRange();
                            range.moveToElementText(cell);
                            range.select();
                        } else if (window.getSelection) {
                            selection = window.getSelection();
                            range = document.createRange();
                            range.selectNodeContents(cell);
                            selection.removeAllRanges();
                            selection.addRange(range);
                        }
                    }}

                    onBlur={e => {
                        // if (validaPermissaoDisabled('CadAdaptativo', 'intervalo_tpn_tpm') === '') {
                        const data = this.state.intervalos;

                        if (data[cellInfo.index][cellInfo.column.id] != e.target.innerHTML) {

                            let valorDigitado = e.target.innerHTML || 0;

                            if (cellInfo.original.descricao === 'TpN') {

                                // valorDigitado = IntervaloValidacao.validaTempoIntervalo(data, cellInfo.column.id, valorDigitado);

                                data[cellInfo.index][cellInfo.column.id] = valorDigitado;

                                // window.$(`.class_tpn_${cellInfo.column.id}`).css('border', 'solid #cccccc 2px');
                                // window.$(`.class_tpn_${cellInfo.column.id}`).css('font-weight', 'normal');
                                // window.$(`.class_tpn_${cellInfo.column.id}`).css('color', '#000000');
                                // if (parseFloat(data[cellInfo.index][cellInfo.column.id]) <= 0) {

                                //     window.$(`.class_tpn_${cellInfo.column.id}`).css('border', 'solid #FF7F00 2px');
                                //     window.$(`.class_tpn_${cellInfo.column.id}`).css('font-weight', 'bold');
                                //     window.$(`.class_tpn_${cellInfo.column.id}`).css('color', '#FF0000');
                                // }


                                e.target.innerHTML = data[cellInfo.index][cellInfo.column.id];
                                // data[0].ciclo_maximo = IntervaloValidacao.getValorCicloMaximo(data, cellInfo.index);
                                for (var key in data) {
                                    data[key].ciclo_maximo = data[0].ciclo_maximo;
                                    this.setState({ data });
                                }
                            } else {
                                data[cellInfo.index][cellInfo.column.id] = valorDigitado;
                                this.setState({ data });
                            }

                            //this.props.update(data[cellInfo.index]);
                        }
                        // }

                    }}

                    dangerouslySetInnerHTML={{
                        __html: valorHTML
                    }}
                />
            );


        } else {// aqui são as fases
            var classe = ['sel-nenhum', 'sel-vermelho', 'sel-amarelo', 'sel-verde', 'sel-vermelho-p', 'sel-amarelo-p', 'sel-verde-p'];
            let classFValida = '';

            classFValida = `class_f_${cellInfo.original.descricao}_${cellInfo.column.id}`;

            return (
                <div
                    style={{

                        textAlign: 'center'
                    }}
                    suppressContentEditableWarning
                    className={`input-gridPlano ${classe[valorAtual]} class_borda_${cellInfo.column.id} ${classFValida}`}
                    // className={`input-gridPlano class_borda_${cellInfo.column.id} mod-p`}

                    dangerouslySetInnerHTML={{
                        __html: this.state.intervalos[cellInfo.index][cellInfo.column.id]
                    }}
                />
            )
        }
    }

    carregaColunas() {

        const intervalo = this.state.intervalos || [];

        var columns = [];

        columns.push({
            accessor: 'descricao', // String-based value accessors!
            width: 40,
            style: {
                padding: '0px',
                height: '20px'
            }
        });

        var property = intervalo[0];

        var idColuna = 0;

        for (var key in property) {
            // if ((key != 'controlador_id') && (key != 'plano_id') && (key != 'descricao') && (key != 'ciclo_maximo') && (key != 'defasagem')) {
            if (['controlador_id', 'plano_id', 'descricao', 'modo_operacao_id', 'ciclo_maximo', 'defasagem', 'tempo_vermelho_min', 'tempo_amarelo_min', 'tempo_verde_min', 'numero_intervalo', 'fase_conflitante'].indexOf(key) < 0) {
                idColuna += 1;
                columns.push({
                    Header: `${idColuna}`,
                    headerClassName: `class_${idColuna} class_borda_${idColuna}`,
                    accessor: `${key}`, // String-based value accessors!
                    width: 34,
                    resizable: true,
                    style: {
                        padding: '0px',
                        height: '20px'
                    },
                    Cell: this.renderEditable
                });
            }
        }

        return columns;
    }

    salvaIntervalo() {

        // const data = this.state.intervalos || [];

        // if (IntervaloValidacao.validaRegrasIntervalo(data)) {
        //     this.setState({ show: true });
        //     this.props.updatePlano(data[0])
        //         .then((result) => {
        //             for (let key in data) {
        //                 this.props.update(data[key]);
        //             }
        //             this.props.initPlano();
        //             this.setState({ show: false });
        //         });
        // } else {
        //     this.setState({ show: false });
        // }     

    }



    render() {

        const intervalo = this.state.intervalos || [];

        if (intervalo.length) {

            const columns = this.carregaColunas();
            // this.setState({ intervalo });

            /* Isso ficou aqui pq é um exemplo de como pegar o array de dentro o state direto. O array vem dentro do "result" do .then */
            // var gtTeste = this.state.data.payload
            //     .then(result => { console.log(result); return result; })
            //     .catch(error => { console.error(error); return Promise.reject(error); });

            return (

                <ReactTableGT2A
                    data={intervalo}
                    columns={columns}
                    defaultPageSize={17}
                    showPagination={false}
                    sortable={false}
                />
            )
        }

        return (
            <div></div>
        )

    }
}

// busca o intervaloPlanoCad la do reducers.js
const selector = formValueSelector('adaptativoForm');
const mapStateToProps = state => ({
    list: state.intervaloAdaptativoCad.list,
    controladorID: selector(state, 'controlador_id'),
    planoID: selector(state, 'plano_id'),
    valores: getFormValues('adaptativoForm')(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({ getIntervaloList, init }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(IntervaloAdaptativoList)
