import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getConfig, setConfig } from '../../configuracao/configLocal';
import { getConfControladorGrupoList } from './selectdbAtions';

class SelectConfControlador extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: getConfig('cont')
        }
    }

    componentWillMount() {
        this.props.getConfControladorGrupoList();
    }

    renderOptions() {
        const list = this.props.list || [];
        const anel = this.props.anel !== false;

        if (list.length === 0) {
            return <option value=''>Carregando...</option>
        }

        let listCont = [];
        let firstAnel = null;

        list.forEach(cont => {
            //se não exibe aneis então ajusta o valor selecionado
            if (!anel) {
                if (cont.anel === 1) {
                    firstAnel = cont.id;
                } else if (cont.id === +this.state.value) {
                    setConfig('cont', firstAnel);
                    this.setState({value: firstAnel});
                }
            }

            listCont.push(cont);
        });


        // `${cont.}|${cont.}`
        return listCont.map(contro => (
            <option
                key={contro.id}
                value={contro.id}
                data-numero={contro.numero_controlador}
                data-anel={contro.anel}
                data-latitude={contro.latitude}
                data-longitude={contro.longitude}
                style={{'display': anel || contro.anel === 1 ? '' : 'none'}}
                >
                {!anel ? 'Controlador ' : ''}
                {contro.numero_controlador}
                {anel ? `- Anel ${contro.anel} (${contro.endereco})` : ''}
            </option>
        ))
    }

    handleChange(event) {
        let changed = (getConfig('cont') != event.target.value);
        this.setState({value: event.target.value });
        setConfig('cont', event.target.value);
        setConfig('numCont', event.target.selectedOptions[0].getAttribute('data-numero'));
        setConfig('anel', event.target.selectedOptions[0].getAttribute('data-anel'));
        setConfig('contLat', event.target.selectedOptions[0].getAttribute('data-latitude'));
        setConfig('contLong', event.target.selectedOptions[0].getAttribute('data-longitude'));

        if (changed && this.props.onChange) {
            this.props.onChange();
        }
    }

    render() {
        // let position =  this.props.position || 'right';

        return (        
            // <div style={{'float': position}}>
            <div style={{marginTop:'5px'}}>
                <label style={{'display': 'inline'}} htmlFor={'selectConfControlador'}><small>Controlador: </small></label>
                <select
                    id={'selectConfControlador'}
                    name={'selectConfControlador'}
                    style={{'width': '200px', 'display': 'inline'}}
                    class={'form-control'}
                    onChange={event=>{this.handleChange(event)}}
                    value={this.state.value}
                >
                    <option value="0">Selecione...</option>
                    {this.renderOptions()}
                </select>
            </div>

        )
    }
}

const mapStateToProps = state => ({ list: state.controladorCad.listConf })
const mapDispatchToProps = dispatch => bindActionCreators({ getConfControladorGrupoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectConfControlador)