const INITIAL_STATE = {
    list: [],
    total: 0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'USER_LOG_FETCHED':
            return { 
                ...state, 
                list: action.payload.data.list, 
                total: action.payload.data.total,
                grupos: action.payload.data.grupos,
                controladores: action.payload.data.controladores,
                ips: action.payload.data.ips,
            }
        case 'USER_LOG_IMAGE_FETCHED':
            return { ...state, image: action.payload.data.image }
        default:
            return state
    }
}