import React, { Component } from 'react';
import { Modal, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getList } from '../../controlador_erro_geral/erroGeralActions';

class NotificationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificacoesSelecionadas: [],
        };
    }
    
    handleOnChange = (event) => {
        const notificacoesSelecionadas = [...this.state.notificacoesSelecionadas];
        const id = event.target.id;

        if (!notificacoesSelecionadas.includes(id)) {
            notificacoesSelecionadas.push(id);
        } else {
            const index = notificacoesSelecionadas.indexOf(id);
            notificacoesSelecionadas.splice(index, 1);
        }
        this.setState({ notificacoesSelecionadas });
    }

    mensagensSelecionadasLida = () => {
        this.state.notificacoesSelecionadas.forEach(chave => {
            const parts = chave.split("_");

            const notification = {
                controlador_id: parts[0],
                data_erro: parts[1],
                hora_erro: parts[2]            
            }

            this.props.clearAlertaErro(notification);
        });

        const notificacoesSelecionadas = [];

        this.setState({ notificacoesSelecionadas });

        this.props.getList(0, [], []);

        toastr.success('Sucesso', 'Todas as mensagens selecionadas foram marcadas como lida.', {
            position: 'bottom-right'
        });
    }
    
    render() {
        const { showModal, handleCloseModal, notifications, clearAlertaErro, handleTodasMensagensLidas } = this.props;

        return (
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                dialogClassName="modal-90w"
                aria-labelledby="notificationModalLabel"
                centered
                className="rounded shadow"
            >
                <Modal.Header closeButton className="bg-primary text-white">
                    {Object.entries(notifications).length > 0 && (
                        <Modal.Title id="notificationModalLabel">
                            {Object.entries(notifications).length > 1
                                ? Object.entries(notifications).length + ' Notificações'
                                : Object.entries(notifications).length + ' Notificação'}
                        </Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body style={{ overflowY: 'auto', maxHeight: '400px' }}>
                    {Object.entries(notifications).length === 0 ? (
                        <h3 style={{ textAlign: 'center' }}>Não há notificações!</h3>
                    ) : (
                        <>
                            <table className="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ width: '5%', textAlign: 'center' }}></th>
                                        <th style={{ width: '10%', textAlign: 'center' }}>Grupo</th>
                                        <th style={{ width: '15%', textAlign: 'center' }}>Controlador</th>
                                        <th style={{ width: '15%', textAlign: 'center' }}>Data</th>
                                        <th style={{ width: '10%', textAlign: 'center' }}>Hora</th>
                                        <th style={{ width: '40%', textAlign: 'center' }}>Mensagem</th>
                                        <th style={{ width: '5%', textAlign: 'center' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(notifications).map(([chave, notification]) => (
                                        <tr key={chave}>
                                            <td className="text-center">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={chave}
                                                    onChange={this.handleOnChange}
                                                    defaultChecked={false}
                                                />
                                            </td>
                                            <td className="text-center">{notification.grupo_fisico_id}</td>
                                            <td className="text-center">{notification.numero_controlador}</td>
                                            <td className="text-center">{moment(notification.data_erro).format('DD/MM/YYYY')}</td>
                                            <td className="text-center">{moment(notification.hora_erro, "HH:mm:ss").format("HH:mm")}</td>
                                            <td className="text-center">{notification.mensagem}</td>
                                            <td className="text-center">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id={`tooltip-${chave}`}>Marcar como lida</Tooltip>}
                                                >
                                                    <Button
                                                        variant="link"
                                                        onClick={async () => clearAlertaErro(notification, true)}
                                                        style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '1.2em', color: 'green' }}
                                                        className="fa fa-check"
                                                    >
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={this.state.notificacoesSelecionadas.length > 0 ? this.mensagensSelecionadasLida : handleTodasMensagensLidas}
                    >
                        {this.state.notificacoesSelecionadas.length > 0 ? 'Marcar como Lida' : 'Marcar todas como Lida'}
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
const mapStateToProps = state => ({ user: state.auth.user })
const mapDispatchToProps = dispatch => bindActionCreators({ getList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal)