import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../form/labelAndSelectForm';


import { getPlanoUnico } from './selectdbAtions';

class SelectPlanoUnico extends Component {

    constructor(props) {
        super(props);

        this.props.getPlanoUnico();
    }

    renderOptions() {
        const list = this.props.listUnico || [];
        const optionPlano = list.map(plano => (
            <option key={plano} value={plano}>Plano {plano}</option>
        ))

        if (this.props['data-plano-piscante']){
            optionPlano.push(<option key={63} value={63}>{'Plano Intermitente'}</option>)
            optionPlano.push(<option key={32} value={32}>{'Plano Apagado'}</option>)
        }

        return optionPlano;
    }

    render() {
        return (
            <Field
                name='plano_id'
                component={LabelAndSelectForm}
                label='Plano'
                cols={this.props.cols}
                validate={this.props.validate}
                onChange={this.props.onChange}
            >
                <option value='0'>Selecione um Plano</option>
                {this.renderOptions()}
            </Field>
        )
    }
}

const mapStateToProps = state => ({ listUnico: state.planoCad.listUnico })
const mapDispatchToProps = dispatch => bindActionCreators({ getPlanoUnico }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectPlanoUnico)

