import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showUpdate, remove } from './adaptativoActions';
import SPButton from '../common/form/spButton';

import ReactTableGT2A from '../common/grid/gridReactTable';
import '../common/grid/grid.css';
import swal from 'sweetalert2';
import PermissaoUsuario from '../auth/permissao';

class AdaptativoList extends Component {

    constructor(props) {
        super(props);

        this.removerAdaptativo = this.removerAdaptativo.bind(this);
    }

    removerAdaptativo(data) {
        swal({
            title: 'Deseja realmente excluir?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {
                this.props.remove(data);
            }
        });
    }

    render() {

        const adaptativo = this.props.list || [];

        const columns = [
            {
                Header: 'Ações',
                Cell: row => (
                    <div>
                        <PermissaoUsuario tela="CadAdaptativo" condicao={true}>
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' data-title='Alterar' onClick={() => this.props.showUpdate(row.original)} />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' data-title='Excluir' onClick={() => this.removerAdaptativo(row.original)} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadAdaptativo" condicao={false}>
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' disabled="disabled" />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' disabled="disabled"/>
                        </PermissaoUsuario>

                    </div>
                ),
                width: 120,
                filterable: true,
                resizable: true,
                className: 'column_icon',
                Filter: () => <div className='rt-th'><span >Filtrar</span></div>
            },
            {
                Header: 'Plano',
                accessor: 'descricao', // String-based value accessors!
                width: 200,
                className: 'column_grid',
                filterable: true,
                resizable: true,
            },
            {
                Header: 'Vel. da Via',
                className: 'column_grid column_center',
                accessor: 'velocidade_via',
                width: 80,
                resizable: true,
            },
            {
                Header: 'Tempo extenção',
                accessor: 'tempo_extencao',
                className: 'column_grid column_center',
                width: 120,
                resizable: true,
            },
            {
                Header: 'Dist. Carro',
                accessor: 'dist_carro',
                className: 'column_grid column_center',                
                width: 80,
                resizable: true,
            },
            {
                Header: 'Multiplicador',
                accessor: 'multiplicador',
                className: 'column_grid column_center',
                width: 100,
                resizable: true,
            },
            {
                Header: '',
                className: 'column_grid',
                resizable: true,
            }
        ];

        return (

            <ReactTableGT2A
                data={adaptativo}
                columns={columns}
                className='-striped -highlight'
                defaultPageSize={10}
            />
        )
    }
}

const mapStateToProps = state => ({ list: state.adaptativoCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ showUpdate, remove }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(AdaptativoList)
