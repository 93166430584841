const INITIAL_STATE = { list: [], listHeader: [], listAnel : [], listConf : [] }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONTROLADOR_FETCHED':
            return { ...state, list: action.payload.data }
        case 'CONTROLADORHEADER_FETCHED':
            return { ...state, listHeader: action.payload.data }
        case 'CONTROLADOR_CONF_FETCHED':
            return { ...state, listConf: action.payload.data }
        case 'CONTROLADORANEL_FETCHED':
            return { ...state, listAnel: action.payload.data }
        case 'CONTROLADORANEIS_FETCHED':
            return { ...state, aneis: action.payload.data }
        case 'CONTROLADOR_PAGE':
            return { ...state, paginaAtual: action.payload };
        default:
            return state
    }
}