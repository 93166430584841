import axiosInstance from '../main/axios';
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'
import { showTabs, selectTab } from '../common/tab/tabActions'
import configLocal from '../configuracao/configLocal';

// inicia com valor para o <select> não ficarem vazio quando salvar sem alterar o <select> no insert
const INITIAL_VALUES = {}

export function getList() {

    let contId = configLocal.getConfig('cont');

    const request = axiosInstance.get(`/trocaplanos/${contId}`);

    return {
        type: 'TROCAPLANO_FETCHED',
        payload: request
    }
}

export const setPaginaAtual = (pagina) => ({
    type: 'TROCAPLANO_PAGE',
    payload: pagina 
});

export function getControladorList() {
    let contId = configLocal.getConfig('cont');
    let numCont = configLocal.getConfig('numCont');

    const request = axiosInstance.get(`/trocasplanosremote/${contId}/${numCont}`);

    return {
        type: 'TROCAPLANO_FETCHED',
        payload: request
    }
}

export function getListPlano() {

    const contId = configLocal.getConfig('cont');
    const request = axiosInstance.get(`/planos/planos/controlador/${contId}`)

    return {
        type: 'PLANO_FETCHED',
        payload: request
    }
}

export function removeTrocaPlanoControlador() {

    let contId = configLocal.getConfig('cont');

    const request = axiosInstance.delete(`/trocaplanos/controlador/deleteall/${contId}`);

    return {
        type: '',
        payload: request
    }
}

export function createUpdateTrocaPlanoControlador(values) {
    let contId = values.controlador_id;
    delete values['plano_tratado'];
   
    const request = axiosInstance.post(`/trocaplanos/controlador/${contId}`, values)
        .then(resp => {
            toastr.success('Sucesso', 'Operação Realizada com sucesso.');
        })
        .catch(e => {
            e.response.data.errors.forEach(error => toastr.error('Erro', error))
        });
    return {
        type: '',
        payload: request
    }
}

export function updateTrocaPlanoControlador(values) {    

    let contId = configLocal.getConfig('cont');

    const request = axiosInstance.put(`/trocasplanoremote/${contId}`, values);

    return {
        type: '',
        payload: request
    }
}

export function copiarTrocasControladores() {
    let contId = configLocal.getConfig('cont');

    const request = axiosInstance.get(`/controladores/copiartrocas/${contId}`);

    return {
        type: '',
        payload: request
    }
}

export function updateTrocaPlanoControladorGeral(values) {    
    let contId = configLocal.getConfig('cont');
    let grupoId = configLocal.getConfig('grupo');

    const request = axiosInstance.put(`/trocasplanoremote/grupo/${contId}/${grupoId}`, values);

    return {
        type: '',
        payload: request
    }
}

function trataSubmitIntermitente(values) {
    values.anel = 0;
    delete values['plano_tratado'];
    for (let index = 1; index <= 4; index++) {
        let name = 'anel' + index;
        if (values[name]) {
            values.anel += Math.pow(2, index - 1);
        }
        delete values[name];
    }
}

export function create(values) {
    return submit(values, 'post');
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete');
}

function submit(values, method) {

    return dispatch => {
        const id = values.id ? values.id : '';
        // const controlador_id = values.controlador_id ? values.controlador_id : '';
        // const plano_id = values.plano_id ? values.plano_id : '';
        const contId = values.controlador_id;

        trataSubmitIntermitente(values);

        return axiosInstance[method](`/trocaplanos/${contId}/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

function trataLoadPlanoIntermitente(values) {
    for (let index = 1; index <= 4; index++) {
        let name = 'anel' + index;
        values[name] = (values.anel & Math.pow(2, index - 1)) > 0;
    }
}

export function showUpdate(trocaPlano) {
    trataLoadPlanoIntermitente(trocaPlano);
    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('trocaPlanoForm', trocaPlano)
    ]
}

export function showDelete(trocaPlano) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('trocaPlanoForm', trocaPlano)
    ]
}

export function init() {
    INITIAL_VALUES.controlador_id = configLocal.getConfig('cont');

    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
        initialize('trocaPlanoForm', INITIAL_VALUES)
    ]
}