import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getList, update } from './permissaoActions';
import Select from '../common/form/select';

import ReactTableGT2A from '../common/grid/gridReactTable';
import Grid from '../common/layout/grid';

class PermissaoList extends Component {

    constructor() {
        super();

        this.renderPermissao = this.renderPermissao.bind(this);
        this.renderDescricao = this.renderDescricao.bind(this);

        this.state = {
            pesrmissoes: []
        };

    }

    componentWillMount() {
        this.setState({ pesrmissoes: this.props['data-list'] });
    }

    renderPermissao(cellInfo) {

        if (this.state.pesrmissoes[cellInfo.index].tipo === 'C') {
            return (

                <Select
                    className='select-grid'
                    contentEditable
                    suppressContentEditableWarning
                    onChange={event => {
    
                        const data = [...this.state.pesrmissoes];
    
                        if (data[cellInfo.index][cellInfo.column.id] !== event.target.value) {
                            data[cellInfo.index][cellInfo.column.id] = event.target.value;
                            this.setState({ data });
    
                            // this.props.update(data[cellInfo.index]);
                        }
                    }}
    
                    value={this.state.pesrmissoes[cellInfo.index][cellInfo.column.id]}
                >
                    <option value='1'>Sim</option>
                    <option value='0'>Não</option>
                </Select>
            );
        } else {
            return (

                <Select
                    className='select-grid'
                    contentEditable
                    suppressContentEditableWarning
                    onChange={event => {
    
                        const data = [...this.state.pesrmissoes];
    
                        if (data[cellInfo.index][cellInfo.column.id] !== event.target.value) {
                            data[cellInfo.index][cellInfo.column.id] = event.target.value;

                            // se uma tela é alterada para não o sistema torca todos os campos dela para não tbm
                            if (parseInt(event.target.value) === 2){
                                for (const key in data) {
                                    if((data[key].t_id === data[cellInfo.index].t_id) && (data[key].tipo === 'C')){
                                        data[key].permissao = 0;
                                    }
                                }
                            }                            
                            
                            this.setState({ data });
                        }
                    }}
    
                    value={this.state.pesrmissoes[cellInfo.index][cellInfo.column.id]}
                >
                    <option value='0'>Sim</option>
                    <option value='2'>Não</option>
                </Select>
            );
        }
        

        
    }

    renderDescricao(cellInfo) {
        let formatAntes = '';
        let formatDepois = '';

        if (this.state.pesrmissoes[cellInfo.index].tipo === 'C') {
            formatAntes = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class='fa fa-long-arrow-alt-right'></i>&nbsp;`;
            formatDepois = '';
        } else {
            formatAntes = `<i class='fa fa-window-maximize'></i>&nbsp;<b>`;
            formatDepois = '</b>'
        }

        return (
            <div

                // contentEditable
                // suppressContentEditableWarning

                dangerouslySetInnerHTML={{
                    __html: formatAntes + this.state.pesrmissoes[cellInfo.index][cellInfo.column.id]+formatDepois
                }}
            />
        );
    }

    render() {

        const permissao = this.state.pesrmissoes || [];

        if (permissao.length) {


            const columns = [

                {
                    Header: 'Descrição',
                    accessor: 'descricao', // String-based value accessors!
                    className: 'column_grid',
                    Cell: this.renderDescricao,
                    width: 300,
                    resizable: true,
                },
                {
                    Header: 'Permissão',
                    accessor: 'permissao',
                    className: 'column_grid column_select',
                    Cell: this.renderPermissao,
                    width: 100,
                    resizable: true,
                },
                {
                    Header: '',
                    accessor: 'vazio',
                    className: 'column_grid',
                    resizable: true,
                }
            ];

            return (

                <div className="panel panel-primary">
                    <div className="panel-heading">Permissão</div>
                    <div className="panel-body">
                        <ReactTableGT2A
                            data={permissao}
                            columns={columns}
                            defaultPageSize={permissao.length}
                            showPaginationBottom={false}
                            className='-striped -highlight'
                        />
                    </div>
                </div>


            )
        }

        return (
            <div></div>
        );
    }
}

const mapStateToProps = state => ({ list: state.permissaoCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ getList, update }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(PermissaoList)
