import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import consts from '../consts';
import {
    getList
    , update
    , initControlador
    , updateControlador
    , updateSelecaoAutomaticaControlador
} from './selecaoAutomaticaAction';

import ReactTableGT2A from '../common/grid/gridReactTable';
import SelectPlanoGrid from '../common/selectdb/selectPlanoGrid';
import LabelAndSelectChange from '../common/form/labelAndSelectChange';
import Button from '../common/form/button';
import ButtonTooltip from '../common/form/buttonTooltip';
import swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import LabelAndInputChange from '../common/form/labelAndInputChange';
import CheckboxChange from '../common/form/checkboxChange';
import Grid from '../common/layout/grid';
import { getConfig } from '../configuracao/configLocal';

class SAPList extends Component {

    constructor() {
        super();

        this.renderPlano = this.renderPlano.bind(this);
        this.renderQuantidade = this.renderQuantidade.bind(this);
        this.geraSAP = this.geraSAP.bind(this);
        this.salvaSAP = this.salvaSAP.bind(this);
        this.salvarSelecaoAutomaticaControlador = this.salvarSelecaoAutomaticaControlador.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.renderLaco = this.renderLaco.bind(this);
        this.chekboxChange = this.chekboxChange.bind(this);

        this.state = {
            show: false,
            sap_laco_Desabled: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']
        };
    }

    componentWillMount() {
        this.props.getList(this.props['data-controlador']);
    }

    renderQuantidade(cellInfo) {
        return (
            <div
                style={{
                    backgroundColor: "#fafafa",
                    textAlign: 'center'
                }}
                // contentEditable
                suppressContentEditableWarning
                onKeyDown={evento => { // aceita só números

                    if ((evento.keyCode !== 48) && (evento.keyCode !== 49) &&
                        (evento.keyCode !== 50) && (evento.keyCode !== 51) &&
                        (evento.keyCode !== 52) && (evento.keyCode !== 53) &&
                        (evento.keyCode !== 54) && (evento.keyCode !== 55) &&
                        (evento.keyCode !== 56) && (evento.keyCode !== 57) &&
                        (evento.keyCode !== 96) && (evento.keyCode !== 97) &&
                        (evento.keyCode !== 98) && (evento.keyCode !== 99) &&
                        (evento.keyCode !== 100) && (evento.keyCode !== 101) &&
                        (evento.keyCode !== 102) && (evento.keyCode !== 103) &&
                        (evento.keyCode !== 104) && (evento.keyCode !== 105) &&
                        (evento.keyCode !== 110) && (evento.keyCode !== 194) &&
                        (evento.keyCode !== 8) && (evento.keyCode !== 9) &&
                        (evento.keyCode !== 46) && (evento.keyCode !== 116) &&
                        (evento.keyCode !== 16) && (evento.keyCode !== 17) &&
                        (evento.keyCode !== 18) && (evento.keyCode !== 20) &&
                        (evento.keyCode !== 35) && (evento.keyCode !== 36) &&
                        (evento.keyCode !== 37) && (evento.keyCode !== 39) &&
                        (evento.keyCode !== 190) && (evento.keyCode !== 113)) {
                        evento.preventDefault();
                        return true;
                    }

                }}
                onFocus={e => {
                    var cell = e.target;
                    var range, selection;
                    if (document.body.createTextRange) {
                        range = document.body.createTextRange();
                        range.moveToElementText(cell);
                        range.select();
                    } else if (window.getSelection) {
                        selection = window.getSelection();
                        range = document.createRange();
                        range.selectNodeContents(cell);
                        selection.removeAllRanges();
                        selection.addRange(range);
                    }
                }}

                onBlur={e => {
                    const data = [...this.props.list];

                    if (data[cellInfo.index][cellInfo.column.id] != e.target.innerHTML) {
                        if (cellInfo.original.descricao !== 'SL') {
                            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML || 0;
                        } else {
                            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                        }

                        this.setState({ data });

                        //this.props.update(data[cellInfo.index]);
                    }
                }}
                dangerouslySetInnerHTML={{
                    __html: this.props.list[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }

    renderPlano(cellInfo) {

        var valorAtua = 0;
        if (this.props.list[cellInfo.index][cellInfo.column.id])
            valorAtua = this.props.list[cellInfo.index][cellInfo.column.id];

        return (
            <SelectPlanoGrid
                data-controlador-id={this.props['data-controlador']}
                className='select-grid'
                onChange={event => {

                    const data = [...this.props.list];

                    if (data[cellInfo.index][cellInfo.column.id] != event.target.value) {
                        data[cellInfo.index][cellInfo.column.id] = event.target.value;
                        this.setState({ data });

                        //this.props.update(data[cellInfo.index]);
                    }
                }}

                value={valorAtua}
            />
        );

    }

    geraSAP() {
        const data = this.props.list || [];

        if ((data[0].sap_capacidade_faixa) && (data[0].sap_capacidade_faixa > 0)) {
            let z = (data[0].sap_capacidade_faixa * 10) / 60 | 0;

            if (data[0].sap_tempo_amostragem == null) {
                data[0].sap_tempo_amostragem = 5;
            }

            let v_Float_x = (data[0].sap_tempo_amostragem * z) / 100

            for (var key in data) {
                // data[key].quantidade = parseInt(data[0].sap_tempo_amostragem/10 * data[key].percentual_ocupacao);
                data[key].quantidade = (v_Float_x * data[key].percentual_ocupacao | 0) / 10 | 0;
            }

            this.setState({ data });
        } else {
            toastr.warning('Alerta', 'O campo "Capacidade da Faixa" deve ser maior que zero');
        }
    }

    salvaSAP() {
        const data = this.props.list || [];

        let validPlan = true;
        for (const key in data) {
            if ((data[key].plano_id === null) || (parseInt(data[key].plano_id) === 0)) {
                validPlan = false;
                break;
            }
        }

        if (!validPlan) {
            swal({
                type: 'warning',
                title: 'O preenchimento da coluna Plano é obrigatório'
            })
        } else {

            // faz o update dos campos sap na tabela controlador
            this.props.updateControlador(data[0]);

            // faz o update na tabela selecao_automatica
            for (var key in data) {
                this.props.update(data[key]);
            }

            this.props.initControlador();
        }
    }

    handleClose() {
        this.setState({ show: false });
    }

    salvarSelecaoAutomaticaControlador() {
        swal({
            title: 'Enviar as configurações de Seleção Automática para a "Central"?',
            html: '<h5>Essa ação vai substituir as configurações existentes na central.</h5>',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {
                this.setState({ show: true }); // da show no modal de aguarde

                const data = this.props.list || [];

                let validPlan = true;
                for (const key in data) {
                    if ((data[key].plano_id === null) || (parseInt(data[key].plano_id) === 0)) {
                        validPlan = false;
                        break;
                    }
                }

                if (!validPlan) {
                    this.setState({ show: false });
                    swal({
                        type: 'warning',
                        title: 'O preenchimento da coluna Plano é obrigatório'
                    })
                } else {
                    this.props.updateSelecaoAutomaticaControlador(data)
                        .then((result) => {
                            this.setState({ show: false });

                            if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                                toastr.error('Erro', result.payload.response.data.errors[0]);
                            } else {
                                toastr.success('Sucesso', 'Seleção Automática enviada com sucesso.');
                            }
                        })
                        .catch(e => {
                            this.setState({ show: false });
                            toastr.error('Erro', e);
                        });
                }
            }
        });
    }

    inputChange(event) {
        const data = this.props.list || [];
        data[0].sap_capacidade_faixa = event.target.value;
        this.setState({ data });
    }

    renderLaco(sentido) {

        const sap = this.props.list || [];
        let lacoObj = [];

        for (let idx = 0; idx < consts.NUM_FASE; idx++) {
            lacoObj.push(<CheckboxChange name={`sap_laco_sentido${sentido}`} type='checkbox' className={`checkbox-sap sap_laco_${idx + 1}`} disabled={this.state.sap_laco_Desabled[idx]} label={`L${idx + 1}`} checked={parseInt(sap[0][`sap_laco_sentido${sentido}`][idx * 2])} onChange={(event) => { this.chekboxChange(event, idx) }}></CheckboxChange>);
        }

        return <div className='div-checkbox'>{lacoObj}</div>


        //               <CheckboxChange name='sap_laco_sentido2'            type='checkbox' className='checkbox-sap sap_laco_1'          disabled={this.state.sap_laco_Desabled[1]} label='L1'          checked={parseInt(sap[0].sap_laco_sentido2[0])}                 onChange={(event) => { this.chekboxChange(event, 0) }}></CheckboxChange>
        //               <CheckboxChange name='sap_laco_sentido2'            type='checkbox' className='checkbox-sap sap_laco_2'          disabled={this.state.sap_laco_Desabled[1]} label='L2'          checked={parseInt(sap[0].sap_laco_sentido2[2])}                 onChange={(event) => { this.chekboxChange(event, 1) }}></CheckboxChange>
        //               <CheckboxChange name='sap_laco_sentido2'            type='checkbox' className='checkbox-sap sap_laco_3'          disabled={this.state.sap_laco_Desabled[1]} label='L3'          checked={parseInt(sap[0].sap_laco_sentido2[4])}                 onChange={(event) => { this.chekboxChange(event, 2) }}></CheckboxChange>
        //               <CheckboxChange name='sap_laco_sentido2'            type='checkbox' className='checkbox-sap sap_laco_4'          disabled={this.state.sap_laco_Desabled[1]} label='L4'          checked={parseInt(sap[0].sap_laco_sentido2[6])}                 onChange={(event) => { this.chekboxChange(event, 3) }}></CheckboxChange>
        //               <CheckboxChange name='sap_laco_sentido2'            type='checkbox' className='checkbox-sap sap_laco_5'          disabled={this.state.sap_laco_Desabled[1]} label='L5'          checked={parseInt(sap[0].sap_laco_sentido2[8])}                 onChange={(event) => { this.chekboxChange(event, 4) }}></CheckboxChange>
        //               <CheckboxChange name='sap_laco_sentido2'            type='checkbox' className='checkbox-sap sap_laco_6'          disabled={this.state.sap_laco_Desabled[1]} label='L6'          checked={parseInt(sap[0].sap_laco_sentido2[10])}                onChange={(event) => { this.chekboxChange(event, 5) }}></CheckboxChange>
        //               <CheckboxChange name='sap_laco_sentido2'            type='checkbox' className='checkbox-sap sap_laco_7'          disabled={this.state.sap_laco_Desabled[1]} label='L7'          checked={parseInt(sap[0].sap_laco_sentido2[12])}                onChange={(event) => { this.chekboxChange(event, 6) }}></CheckboxChange>
        //               <CheckboxChange name='sap_laco_sentido2'            type='checkbox' className='checkbox-sap sap_laco_8'          disabled={this.state.sap_laco_Desabled[1]} label='L8'          checked={parseInt(sap[0].sap_laco_sentido2[14])}                onChange={(event) => { this.chekboxChange(event, 7) }}></CheckboxChange>
    }

    chekboxChange(event, idx) {

        const data = this.props.list || [];
        let valor = data[0][event.target.name].split("|");
        let valorNovo = '';

        valor[idx] = valor[idx] === '1' ? '0' : '1';
        valorNovo = valor.join("|");

        data[0][event.target.name] = valorNovo;
        this.setState({ data });
    }

    // é chamado quando termina de carregar todo o componente
    componentDidUpdate() {
        const data = this.props.list || [];
        let valor = [];

        for (let laco = 1; laco <= consts.NUM_FASE; laco++) {
            //reativa todos os laços casa o usuário tenha desmarcado um checkbox
            window.$(`.sap_laco_${laco}`).removeAttr('disabled');
            for (let sentido = 1; sentido <= 4; sentido++) {
                valor = data[0][`sap_laco_sentido${sentido}`].split('|');
                if (parseInt(valor[laco - 1]) === 1) {
                    window.$(`.sap_laco_${laco}`).attr('disabled', 'disabled');
                    break;
                }
            }

            window.$(`.sap_laco_${laco}`).each(function (index) {

                if (window.$(this).prop("checked")) {
                    window.$(this).removeAttr('disabled');
                }
            });
        }
    }

    render() {
        const sap = this.props.list || [];

        if (sap.length) {

            const columns = [

                // {
                //     Header: 'Controlador',
                //     accessor: 'controlador_id', // String-based value accessors!
                //     width: 90,
                // },
                {
                    Header: 'Seleção',
                    accessor: 'id', // String-based value accessors!
                    className: 'column_grid column_center',
                    width: 60,
                    resizable: true,
                },
                {
                    Header: 'Percentual',
                    accessor: 'percentual_ocupacao',
                    className: 'column_grid column_center',
                    width: 90,
                    resizable: true,
                },
                {
                    Header: 'Quantidade',
                    accessor: 'quantidade',
                    Cell: this.renderQuantidade,
                    className: 'column_grid column_center',
                    width: 80,
                    resizable: true,
                },
                {
                    Header: 'Plano',
                    accessor: 'plano_id',
                    className: 'column_grid column_select',
                    Cell: this.renderPlano,
                    width: 200,
                    resizable: true,
                },
                {
                    Header: '',
                    accessor: 'vazio',
                    className: 'column_grid',
                    resizable: true,
                }
            ];

            return (

                <div>
                    <Modal
                        show={this.state.show}
                        onHide={this.handleClose}
                        dialogClassName='modal-load'
                        backdrop={false} >
                        <Modal.Body className='modal-body modal-load-body'>
                            <div >
                                <div >
                                    <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                                </div>
                                <div >
                                    <h4><b>Aguarde, processando...</b></h4>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <div className='box-body'>

                        <LabelAndInputChange
                            name="defasagem"
                            type='number'
                            label='Controlador'
                            cols='12'
                            readOnly={true}
                            value={this.props.list[0].numero_controlador}
                        // defaultValue={intervalo[0].defasagem}
                        // onChange={this.inputChange}
                        />

                        <LabelAndSelectChange
                            id='sap_tempo_amostragem'
                            name='sap_tempo_amostragem'
                            label='Tempo Amostragem'
                            cols='12'
                            onChange={event => {

                                const data = [...this.props.list];

                                if (data[0].sap_tempo_amostragem != event.target.value) {
                                    data[0].sap_tempo_amostragem = parseInt(event.target.value);
                                    this.setState({ data });
                                }

                            }}
                            value={this.props.list[0].sap_tempo_amostragem || 0}
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                        </LabelAndSelectChange>

                        <LabelAndInputChange
                            type='number'
                            name='sap_capacidade_faixa'
                            id='sap_capacidade_faixa'
                            cols='12'
                            label='Capacidade da Faixa'
                            placeholder='Informe a capacidade da faixa'
                            onBlur={event => {
                                const data = [...this.props.list];

                                if (data[0].sap_capacidade_faixa != event.target.value) {
                                    data[0].sap_capacidade_faixa = parseInt(event.target.value);
                                    this.setState({ data });
                                }

                            }}
                            value={this.props.list[0].sap_capacidade_faixa || 0}
                            onChange={this.inputChange}
                        />

                        <LabelAndInputChange
                            type="text"
                            name="sap_sentido1"
                            id="sap_sentido1"
                            cols="5"
                            label="Sentido 1"
                            placeholder="Informe a descrição"
                            onBlur={event => {
                                const data = [...this.props.list];

                                if (data[0].sap_sentido1 != event.target.value) {
                                    data[0].sap_sentido1 = event.target.value;
                                    this.setState({ data });
                                }
                            }}
                            value={this.props.list[0].sap_sentido1}
                            onChange={event => {
                                const data = this.props.list || [];
                                data[0].sap_sentido1 = event.target.value;
                                this.setState({ data });
                            }}
                        />

                        <Grid cols='7'>
                            {this.renderLaco(1)}
                        </Grid>

                        <LabelAndInputChange
                            type="text"
                            name="sap_sentido2"
                            id="sap_sentido2"
                            cols="5"
                            label="Sentido 2"
                            placeholder="Informe a descrição"
                            onBlur={event => {
                                const data = [...this.props.list];

                                if (data[0].sap_sentido2 != event.target.value) {
                                    data[0].sap_sentido2 = event.target.value;
                                    this.setState({ data });
                                }
                            }}
                            value={this.props.list[0].sap_sentido2}
                            onChange={event => {
                                const data = this.props.list || [];
                                data[0].sap_sentido2 = event.target.value;
                                this.setState({ data });
                            }}
                        />

                        <Grid cols='7'>
                            {this.renderLaco(2)}
                        </Grid>

                        <LabelAndInputChange
                            type="text"
                            name="sap_sentido3"
                            id="sap_sentido3"
                            cols="5"
                            label="Sentido 3"
                            placeholder="Informe a descrição"
                            onBlur={event => {
                                const data = [...this.props.list];

                                if (data[0].sap_sentido3 != event.target.value) {
                                    data[0].sap_sentido3 = event.target.value;
                                    this.setState({ data });
                                }
                            }}
                            value={this.props.list[0].sap_sentido3}
                            onChange={event => {
                                const data = this.props.list || [];
                                data[0].sap_sentido3 = event.target.value;
                                this.setState({ data });
                            }}
                        />

                        <Grid cols='7'>
                            {this.renderLaco(3)}
                        </Grid>

                        <LabelAndInputChange
                            type="text"
                            name="sap_sentido4"
                            id="sap_sentido4"
                            cols="5"
                            label="Sentido 4"
                            placeholder="Informe a descrição"
                            onBlur={event => {
                                const data = [...this.props.list];

                                if (data[0].sap_sentido4 != event.target.value) {
                                    data[0].sap_sentido4 = event.target.value;
                                    this.setState({ data });
                                }
                            }}
                            value={this.props.list[0].sap_sentido4}
                            onChange={event => {
                                const data = this.props.list || [];
                                data[0].sap_sentido4 = event.target.value;
                                this.setState({ data });
                            }}
                        />

                        <Grid cols='7'>
                            {this.renderLaco(4)}
                        </Grid>

                    </div>
                    <div className='box-footer'>
                        <Button type='submit' className='success' icon='arrow-down' label='Gerar SAP' onClick={this.geraSAP} />
                    </div>


                    <ReactTableGT2A
                        data={sap}
                        columns={columns}
                        defaultPageSize={20}
                        showPaginationBottom={false}
                        className='-striped -highlight'
                    />

                    <div className='box-footer'>
                        <Button type='button' className="primary" icon='check' label='Salvar' onClick={this.salvaSAP} />
                        <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.initControlador} />
                        <ButtonTooltip type='button' id='btnSelecaoAutomaticaEnviarControlador' className='warning' icon='upload' label='Enviar Controlador' data-title='Envia os dados para o controlador' onClick={this.salvarSelecaoAutomaticaControlador} />
                    </div>

                </div>
            );

        };

        return (
            <div></div>
        );


    }
}

const mapStateToProps = state => ({ list: state.selecaoAutomaticaCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ getList, update, initControlador, updateControlador, updateSelecaoAutomaticaControlador }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SAPList)
