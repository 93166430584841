import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import Moment from 'moment';
import { connect } from 'react-redux';
import { getList, getDescriptions, setPaginaAtual } from './contagemVolumetricaActions';
import { Modal } from 'react-bootstrap';
import { parseISO, format, isValid } from 'date-fns';
import ReactTableGT2A from '../common/grid/gridReactTable';
import LabelTooltip from '../common/form/labelTooltip';
import { PAGE_SIZE } from '../common/utils/pageSize';

class ControladorContagemVolumetricaList extends Component {

    constructor() {
        super();

        this.handleClose = this.handleClose.bind(this);
        this.handleChangeTime = this.handleChangeTime.bind(this);
        this.fetchData = this.fetchData.bind(this);

        this.state = {
            show: false,
            data: [],
            pages: null,
            loading: true,
        };
    }

    handlePageChange = (page) => {
        this.props.setPaginaAtual(page); // Atualiza a página no Redux
    };

    componentWillMount() {
        this.props.getDescriptions();
    }

    fetchData(state) {
        this.setState({ loading: true });

        this.props.getList(
          state.page,
          state.sorted,
          state.filtered
        ).then(() => {
            this.setState({
                loading: false
            });
        });
    }

    timeFormat(time) {
        let timeFormatted = time.replace(/\D/g, '');
        let hour = timeFormatted.substring(0, 2);
        let minute = timeFormatted.substring(2, 4);
        let second = timeFormatted.substring(4, 6);
        hour = hour > 23 ? '23' : hour;
        minute = minute > 59 ? '59' : minute;
        second = second > 59 ? '59' : second;
        return `${hour}${minute ? ':' + minute : ''}${second ? ':' + second : ''}`;
    }

    handleChangeTime(value, onChange) {
        const time = this.timeFormat(value);
        onChange(time);
    }

    handleClose() {
        this.setState({ show: false });
    }

    render() {
        Moment.locale('pt-br');
        const descricoes = this.props.descriptions || {};
        const { data, total } = this.props;
        const paginaAtual = this.props.paginaAtual;
        const pages = Math.ceil(total / PAGE_SIZE);

        const columns = [
            {
                Header: 'Data Contagem',
                accessor: 'data_contagem',
                width: 150,
                filterable: true,
                resizable: true,
                className: 'column_grid column_center',
                Cell: ({ value }) => (Moment(value).format('DD/MM/YYYY')),
                Filter: ({ filter, onChange }) => (
                    <input
                        type='date'
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : ''}
                    />
                ),
                filterMethod: (filter, row) => {
                    if (isValid(parseISO(row[filter.id]))) {
                        const dataValue = format(parseISO(row[filter.id]), 'yyyy-MM-dd');
                        return dataValue === filter.value;
                    }
                }
            },
            {
                Header: 'Hora Contagem',
                accessor: 'hora_contagem',
                width: 120,
                className: 'column_grid column_center',
                filterable: true,
                resizable: true,
                Filter: ({ filter, onChange }) => (
                    <input  
                        onChange={event => this.handleChangeTime(event.target.value, onChange)}
                        value={filter ? filter.value : ''}
                        type='text'
                        maxLength={8}
                        placeholder='hh:mm:ss'
                        className='form-control'
                    />
                )
            }
        ];

        for (let laco = 1; laco <= 16; laco++) {
            let label = 'Laço ' + laco,
                key = 'laco_' + laco;
            if (descricoes.hasOwnProperty(key)) {
                label = <LabelTooltip text={label} className='text-underline' tooltip={descricoes[key]} />
            }

            columns.push({
                Header: label,
                accessor: 'laco_' + laco,
                filterable: true,
                resizable: true,
                className: 'column_grid column_center',
                width: 80
            });
        }

        return (
            <div>
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    dialogClassName='modal-load'
                    backdrop={false}
                >
                </Modal>

                <ReactTableGT2A
                    manual
                    data={data}
                    columns={columns}
                    pages={pages}
                    onFetchData={this.fetchData}
                    loading={this.state.loading}
                    filterable
                    className='-striped -highlight'
                    defaultPageSize={PAGE_SIZE}
                    showPaginationBottom={true}
                    onPageChange={this.handlePageChange} 
                    page={paginaAtual} 
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({ 
    data: state.controladorContagemVolumetrica.list,
    descriptions: state.controladorContagemVolumetrica.descriptions,
    total: state.controladorContagemVolumetrica.total,
    paginaAtual: state.controladorContagemVolumetrica.paginaAtual  
});
const mapDispatchToProps = dispatch => bindActionCreators({ getList, getDescriptions, setPaginaAtual }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ControladorContagemVolumetricaList);
