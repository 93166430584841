import axiosInstance from '../main/axios';
import { showTabs, selectTab } from '../common/tab/tabActions'
import configLocal from '../configuracao/configLocal';
import userLocal from '../configuracao/userLocal';

const INITIAL_VALUES = {}

export function getList() {

    var grupoId = configLocal.getConfig('grupo');
    var numCont = configLocal.getConfig('numCont');

    const request = axiosInstance.get(`/controladoreserro/${grupoId}/${numCont}`, {
        params: { email: userLocal.getUserConfig('email') }
    })

    return {
        type: 'CONTROLADOR_ERRO_FETCHED',
        payload: request
    }
}

export const setPaginaAtual = (pagina) => ({
    type: 'ERRO_PAGE',
    payload: pagina 
});

export function getAlertaErro() {

    // var contId = configLocal.getConfig('cont');
    const email = userLocal.getUserConfig('email');

    const request = axiosInstance.get(`/controladoreserro/${email}`)

    return {
        type: '',
        payload: request
    }
}

export function clearAlertaErro(alerts) {

    const request = axiosInstance.put(`/controladoreserro`, { alerts: alerts });

    return {
        type: '',
        payload: request
    }
}


export function getRemoteList() {

    const contId = configLocal.getConfig('cont');
    const numCont = configLocal.getConfig('numCont');

    const request = axiosInstance.get(`/controladoreserro/remote/${contId}/${numCont}`, {
        params: { email: userLocal.getUserConfig('email') }
    });

    return {
        type: '',
        payload: request
    }
}

export function init() {
    INITIAL_VALUES.controlador_id = configLocal.getConfig('cont');
    return [
        showTabs('tabList'),
        selectTab('tabList'),
        getList()
    ]
}
