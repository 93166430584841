const INITIAL_STATE = {list: []}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONTROLADOR_CONTAGEM_VOLUMETRICA_FETCHED':
            return { 
                ...state,
                list: action.payload.data.list,
                total: action.payload.data.total
            }
        case 'DESCRICAO_CONTAGEM_VOLUMETRICA_FETCHED':
            return { ...state, descriptions: action.payload.data }
        case 'CONTAGEM_VOLUMETRICA_PAGE':
            return { ...state, paginaAtual: action.payload };    
        default:
            return state
    }
}