import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ContentHeader from '../common/template/contentHeader'
import Content from '../common/template/content'
import Tabs from '../common/tab/tabs'
import TabsHeader from '../common/tab/tabsHeader'
import TabsContent from '../common/tab/tabsContent'
import TabHeader from '../common/tab/tabHeader'
import TabContent from '../common/tab/tabContent'
import { init, setPaginaAtual } from './erroActions'

import List from './erroList';
import SelectConfControlador from '../common/selectdb/selectConfControlador'
import SelectConfGrupoFisico from '../common/selectdb/selectConfGrupoFisico'

class ControladorErro extends Component {

    componentWillMount() {
        this.props.init();
    }

    changeControlador() {
        this.props.setPaginaAtual(0);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Erro Controlador' small='Consulta' content={<span className='contentHeaderFilter'> <SelectConfGrupoFisico onChange={this.changeControlador} /> <SelectConfControlador anel={false} onChange={this.changeControlador} /> </span>} />
                <Content>
                    <Tabs>
                        <TabsHeader>
                            <TabHeader label='Listar' icon='bars' target='tabList' />
                        </TabsHeader>
                        <TabsContent>
                            <TabContent id='tabList'>
                                <List />
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ init, setPaginaAtual }, dispatch)
export default connect(null, mapDispatchToProps)(ControladorErro)