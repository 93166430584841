import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ContentHeader from '../common/template/contentHeader'
import Content from '../common/template/content'
import Tabs from '../common/tab/tabs'
import TabsHeader from '../common/tab/tabsHeader'
import TabsContent from '../common/tab/tabsContent'
import TabHeader from '../common/tab/tabHeader'
import TabContent from '../common/tab/tabContent'
import { init, create, update, getList, setPaginaAtual} from './controladorActions'

import List from './controladorList';
import Form from './controladorForm';
import ListControladorFase from './controladorFaseList';
import ListControladorSAP from './controladorSAP';
import PermissaoUsuario from '../auth/permissao';
import SelectConfGrupoFisico from '../common/selectdb/selectConfGrupoFisico'

class Controlador extends Component {

    componentWillMount() {
        this.changeGrupo = this.changeGrupo.bind(this);
        this.props.init();
    }

    changeGrupo() {
        this.props.getList();
        this.props.setPaginaAtual(0);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Controlador' small='Cadastro' content={<span className='contentHeaderFilter'> <SelectConfGrupoFisico onChange={this.changeGrupo} /> </span>} />
                <Content>
                    <Tabs>
                        <TabsHeader>
                            <TabHeader label='Listar' icon='bars' target='tabList' />
                            <PermissaoUsuario tela="CadControlador" condicao={true}>
                                <TabHeader label='Incluir' icon='plus' target='tabCreate' />
                            </PermissaoUsuario>
                            <TabHeader label='Alterar' icon='pencil-alt' target='tabUpdate' closeAction={true} />
                            {/* <TabHeader label='Configurar Fases' icon='traffic-light' target='tabFase' /> */}
                            <TabHeader label='Seleção Automática de Planos' icon='car' target='tabSAP' />
                            {/* <TabHeader label='Planos' icon='sitemap' target='tabPlano' /> */}
                            
                        </TabsHeader>
                        <TabsContent>
                            <TabContent id='tabList'>
                                <List/>
                            </TabContent>
                            <TabContent id='tabCreate'>
                                <Form onSubmit={this.props.create} submitClass='primary' submitLabel='Salvar' />
                            </TabContent>
                            <TabContent id='tabUpdate'>
                                <Form onSubmit={this.props.update} readOnly={true} submitClass='primary' submitLabel='Salvar' />
                            </TabContent>
                            <TabContent id='tabFase'>
                                <ListControladorFase />
                            </TabContent>
                            <TabContent id='tabSAP'>
                                <ListControladorSAP />
                            </TabContent>
                            <TabContent id='tabPlano'>
                                <Form onSubmit={this.props.update} readOnly={false} submitClass='primary' submitLabel='Salvar' />
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ init, create, update, getList, setPaginaAtual }, dispatch)
export default connect(null, mapDispatchToProps)(Controlador)