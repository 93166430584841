import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './grid.css';

const ReactTableGT2A = ({ page, onPageChange, ...props }) => {
  return (
    <ReactTable
      {...props}
      page={page}
      onPageChange={onPageChange} 
      loading={props.loading || false}
      loadingText='Carregando...'
      showPageSizeOptions={false}
      showPageJump={false}
      resizable={false}
      showFilters={true}
      previousText={<div><i className='fa fa-reply'></i> <span>Anterior</span></div>}
      nextText={<div><i className='fa fa-share'></i> <span>Próximo</span></div>}
      noDataText='Nenhum registro encontrado'
      pageText='Página'
      ofText='de'
      rowsText='linhas'
    />
  );
};

export default ReactTableGT2A;