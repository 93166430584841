const INITIAL_STATE = { list: [], listCod: [], paginaAtual: 0 }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PLANO_FETCHED':
            return { ...state, list: action.payload.data }
        case 'PLANOCONTROLADOR_FETCHED':
            return { ...state, list: action.payload.data }
        case 'PLANOUNICO_FETCHED':
            return { ...state, listUnico: action.payload.data }
        case 'PLANOCODIGO_FETCHED':
            return { ...state, listCod: action.payload.data }
        case 'PLANO_PAGE':
                return { ...state, paginaAtual: action.payload };
        default:
            return state
    }
}