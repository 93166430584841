import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { getList, getImageById } from './logUsuarioActions';
import SPButton from '../common/form/spButton';
import Button from '../common/form/button';
import ReactTable from '../common/grid/gridReactTable';
import If from '../common/operator/if';
import { PAGE_SIZE } from '../common/utils/pageSize';

moment.locale('pt-br');

class LogUsuarioList extends Component {

    constructor(props) {
        super(props);

        this.getImage = this.getImage.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.handleChangeTime = this.handleChangeTime.bind(this);

        this.state = {
            show: false,
            html: '',
            dataAlteracao: '',
            data: [],
            pages: null,
            loading: true
        };

        this.fetchData = this.fetchData.bind(this);
    }

    fetchData(state) {
        this.setState({ loading: true });

        this.props.getList(
          state.page,
          state.sorted,
          state.filtered
        ).then(() => {
            this.setState({
                loading: false
            });
        });
      }

    handleClose() {
        this.setState({ show: false });
    }

    async getImage(id, data) {
        this.setState({ loading: true });
        const image = await this.props.getImageById(id).then(() => {
            return this.props.image;
        });
        this.setState({ loading: false });

        if(image !== null) {
            this.setState({ html:  <img src={`${image}`} alt="" />, dataAlteracao: moment(data).format('DD/MM/YYYY HH:mm:ss') });
            this.setState({ show: true });
        }
    }

    timeFormat(time) {
        let timeFormatted = time.replace(/\D/g, '');
        let hour = timeFormatted.substring(0, 2);
        let minute = timeFormatted.substring(2, 4);
        let second = timeFormatted.substring(4, 6);
        hour = hour > 23 ? '23' : hour;
        minute = minute > 59 ? '59' : minute;
        second = second > 59 ? '59' : second;
        return `${hour}${minute ? ':' + minute : ''}${second ? ':' + second : ''}`;
    }

    handleChangeTime(value, onChange) {
        const time = this.timeFormat(value);
        onChange(time);
    }

    render() {
        const { data, total, grupos, controladores, ips } = this.props;
        const pages = Math.ceil(total / PAGE_SIZE);
        const columns = [
            {
                Header: 'Ações',
                Cell: row => (
                    <If condicao={!!row.original.has_image}>
                        <SPButton type='button' className='success btn btn-sm' icon='search' data-title='' onClick={() => this.getImage(row.original.id, row.original.data_registro)} />
                    </If>
                ),
                className: 'column_icon',
                width: 80,
                sortable: false,
                filterable: true,
                resizable: true,
                Filter: () => <div className='rt-th'><span >Filtrar</span></div>
            },
            {
                Header: 'Tela',
                accessor: 'tabela_nome',
                width: 150,
                className: 'column_grid',
                filterable: true,
                resizable: true,
                Cell: ({ value }) => {
                    switch (value) {
                        case 'controlador': return 'Controlador'
                        case 'data_especial': return 'Data Especial'
                        case 'intervalo': return 'Intervalo Plano'
                        case 'plano': return 'Plano'
                        case 'tabela_troca': return 'Tabela de Troca'
                        case 'plano_forcado': return 'Plano Forçado'
                        default: return true
                    }
                },
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={e => onChange(e.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "Todos"}
                        name='tabela_nome'
                    >
                        <option value="">Todos</option>
                        <option value="controlador">Controlador</option>
                        <option value="data_especial">Data Especial</option>
                        <option value="intervalo">Intervalo Plano</option>
                        <option value="plano">Plano</option>
                        <option value="tabela_troca">Tabela de Troca</option>
                        <option value="plano_forcado">Plano Forçado</option>
                    </select>
            },
            {
                Header: 'Usuário',
                accessor: 'usuario_desc',
                width: 80,
                className: 'column_grid',
                filterable: true,
                resizable: true,
            },
            {
                Header: 'Data',
                accessor: 'data_registro',
                width: 150,
                filterable: true,
                resizable: true,
                className: 'column_grid column_center',
                Cell: ({ value }) => (moment(value).format('DD/MM/YYYY')),
                Filter: ({ filter, onChange }) => (
                    <input  
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{ width: "100%" }}
                        type='date'
                        name='data_registro'
                        className='form-control'
                    />
                )
            },

            {
                Header: 'Hora',
                accessor: 'hora_registro',
                width: 120,
                className: 'column_grid column_center',
                filterable: true,
                resizable: true,
                Filter: ({ filter, onChange }) => (
                    <input  
                        onChange={event => this.handleChangeTime(event.target.value, onChange)}
                        value={filter ? filter.value : ''}
                        type='text'
                        name='hora_registro'
                        maxLength={8}
                        placeholder='hh:mm:ss'
                        className='form-control'
                    />
                )
            },
            {
                Header: 'Movimento',
                accessor: 'tipo',
                width: 100,
                className: 'column_grid column_center',
                filterable: true,
                resizable: true,
                Cell: ({ value }) => {
                    switch (value) {
                        case 1: return 'Cadastro'
                        case 2: return 'Alteração'
                        case 3: return 'Exclusão'
                        default: return true
                    }
                },
                filterMethod: (filter, row) => {
                    switch (filter.value) {
                        case 'Cadastro': return parseInt(row[filter.id]) === 1;
                        case 'Alteração': return parseInt(row[filter.id]) === 2;
                        case 'Exclusão': return parseInt(row[filter.id]) === 3;
                        default: return true;
                    }
                },
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "Todos"}
                        name='tipo'
                    >
                        <option value="">Todos</option>
                        <option value="1">Cadastro</option>
                        <option value="2">Alteração</option>
                        <option value="3">Exclusão</option>
                    </select>
            },
            {
                Header: 'Grupo',
                accessor: 'grupo_fisico',
                width: 60,
                className: 'column_grid column_center',
                filterable: true,
                resizable: true,
                Filter: ({ filter, onChange }) => {
                    const value = filter ? filter.value : '';
                    return (
                        <select
                            class={'form-control'}
                            onChange={event => onChange(event.target.value)}
                            value={value}
                        >
                            <option value="">Todos</option>
                            {grupos && grupos.map((grupo) => (
                                <option key={grupo} value={grupo}>{grupo}</option>
                            ))}
                        </select>
                    )
                }
            },
            {
                Header: 'Controlador',
                accessor: 'numero_controlador',
                width: 90,
                filterable: true,
                className: 'column_grid column_center',
                resizable: true,
                Filter: ({ filter, onChange }) => {
                    const value = filter ? filter.value : '';
                    return (
                        <select
                            class={'form-control'}
                            onChange={event => onChange(event.target.value)}
                            value={value}
                        >
                            <option value="">Todos</option>
                            {controladores && controladores.map((controlador) => (
                                <option key={controlador} value={controlador}>{controlador}</option>
                            ))}
                        </select>
                    )
                }
            },
            {
                Header: 'IP/Porta',
                accessor: 'ip_porta',
                width: 150,
                filterable: true,
                resizable: true,
                className: 'column_grid column_center',
                Filter: ({ filter, onChange }) => {
                    const value = filter ? filter.value : '';
                    return (
                        <select
                            class={'form-control'}
                            onChange={event => onChange(event.target.value)}
                            value={value}
                        >
                            <option value="">Todos</option>
                            {ips && ips.map((ip) => (
                                <option key={ip} value={ip}>{ip}</option>
                            ))}
                        </select>
                    )
                }
            },
            {
                Header: 'Anel',
                accessor: 'anel',
                width: 50,
                filterable: true,
                resizable: true,
                className: 'column_grid column_center',
            },
            {
                Header: 'Código Tela',
                accessor: 'codigo_id',
                width: 90,
                filterable: true,
                resizable: true,
                showFilters: false,
                className: 'column_grid column_center',
            }
        ];

        return (
            <>
                <Modal show={this.state.show} onHide={this.handleClose} dialogClassName='modal-mapa'>
                    <Modal.Header className='modal-header' closeButton>
                        <Modal.Title>Log de Alteração {this.state.dataAlteracao}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal-body' align="center">
                        {this.state.html}
                    </Modal.Body>
                    <Modal.Footer className='modal-header'>
                        <Button type='button' className='primary' icon='close' label='Fechar' onClick={this.handleClose} />
                    </Modal.Footer>
                </Modal>

                <ReactTable
                    manual
                    data={data}
                    columns={columns}
                    pages={pages}
                    loading={this.state.loading}
                    onFetchData={this.fetchData}
                    filterable
                    className='-striped -highlight'
                    defaultPageSize={PAGE_SIZE}
                    showPaginationBottom={true}
                />
            </>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getList, getImageById }, dispatch)
export default connect(state => ({
    data: state.logusuarioCad.list,
    total: state.logusuarioCad.total,
    grupos: state.logusuarioCad.grupos,
    controladores: state.logusuarioCad.controladores,
    ips: state.logusuarioCad.ips,
    image: state.logusuarioCad.image
}), mapDispatchToProps)(LogUsuarioList);