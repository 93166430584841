import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showUpdate, remove, setPaginaAtual } from './grupoFisicoActions';
import SPButton from '../common/form/spButton';
import PermissaoUsuario from '../auth/permissao';

import ReactTableGT2A from '../common/grid/gridReactTable';
import swal from 'sweetalert2';

class GrupoFisicoList extends Component {

    constructor(props) {
        super(props);

        this.removerGrupo = this.removerGrupo.bind(this);
    }

    handlePageChange = (page) => {
        this.props.setPaginaAtual(page);
    };

    removerGrupo(data) {
        swal({
            title: 'Deseja realmente excluir?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {
                this.props.remove(data);
            }
        });
    }

    render() {

        const grupoFisico = this.props.list || [];
        const paginaAtual = this.props.paginaAtual;

        const columns = [
            {
                Header: 'Ações',
                Cell: row => (
                    <div>
                        <PermissaoUsuario tela="CadGrupoFisico" condicao={true}>
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' data-title='Alterar' onClick={() => this.props.showUpdate(row.original)} />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' data-title='Excluir' onClick={() => this.removerGrupo(row.original)} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadGrupoFisico" condicao={false}>
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' disabled="disabled" />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' disabled="disabled" />
                        </PermissaoUsuario>
                    </div>
                ),
                className: 'column_icon',
                width: 90,
                resizable: true,
                Filter: () => <div className='rt-th'><span >Filtrar</span></div>
            },
            {
                Header: 'Código',
                accessor: 'id', // String-based value accessors!
                width: 60,
                filterable: true,
                resizable: true,
                className: 'column_grid column_center',
                Filter: () => <div className='rt-th'><span >Filtrar</span></div>
            },
            {
                Header: 'Descrição',
                accessor: 'descricao',
                className: 'column_grid',
                filterable: true,
                resizable: true,
            },
            {
                Header: 'Ordem',
                accessor: 'ordem',
                width: 60,
                className: 'column_grid',
                filterable: true,
                resizable: true,
            }
        ];

        return (

            <ReactTableGT2A
                data={grupoFisico}
                columns={columns}
                className='-striped -highlight'
                defaultPageSize={15}
                onPageChange={this.handlePageChange} 
                page={paginaAtual} 
            />
        )
    }
}
// busca o grupoCad la do reducers.js
const mapStateToProps = state => ({ 
    list: state.grupoFisicoCad.list, 
    paginaAtual: state.grupoFisicoCad.paginaAtual    
})
const mapDispatchToProps = dispatch => bindActionCreators({ showUpdate, remove, setPaginaAtual }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(GrupoFisicoList)
