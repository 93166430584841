const INITIAL_STATE = {list: []}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONTROLADOR_ERRO_FETCHED':
            return { ...state, list: action.payload.data }
        case 'ERRO_PAGE':
                return { ...state, paginaAtual: action.payload };
        default:
            return state
    }
}