import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSummary, getDadosDashboard } from './dashboardActions';
import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ValueBox from '../common/widget/valueBox';
import Row from '../common/layout/row';
import { Panel, Modal } from 'react-bootstrap';
import GraficoContagemLaco from '../contagem/graficoContagemLaco';
import GraficoContagemControlador from '../contagem/graficoContagemControlador';
import { toastr } from 'react-redux-toastr';
import Button from '../common/form/button';
import moment from 'moment';
import LabelAndInputChange from '../common/form/labelAndInputChange';
import '../common/form/form.css';
import { getContagemGraficoList } from '../contagem/contagemActions';
import SelectConfGrupoFisico from '../common/selectdb/selectConfGrupoFisico';
import SelectConfControlador from '../common/selectdb/selectConfControlador';
import "../common/selectdb/contentHeaderFilter.css";

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            totalControladores: 0,
            totalVeiculos: 0,
            totalAlertas: 0,
            loop: 0,
            showFiltro: false,
            filtroAux: {
                dataInicial: moment().format('YYYY-MM-DD'),
                dataFinal: moment().format('YYYY-MM-DD')
            },
            filtro: {
                dataInicial: moment().format('YYYY-MM-DD'),
                dataFinal: moment().format('YYYY-MM-DD')
            }
        };
        this.intervalValorDashboard = 0;

        this.aplicarFiltro = this.aplicarFiltro.bind(this);
        this.valuesDashboard = this.valuesDashboard.bind(this);
        this.handleCloseFiltro = this.handleCloseFiltro.bind(this);
        this.handleShowFiltro = this.handleShowFiltro.bind(this);
        this.onChangeDataInicial = this.onChangeDataInicial.bind(this);
        this.onChangeDataFinal = this.onChangeDataFinal.bind(this);
        this.limparFiltro = this.limparFiltro.bind(this);
        this.changeControlador = this.changeControlador.bind(this);
    }

    aplicarFiltro() {
        let filtroTela = this.state.filtroAux;

        let filtroNovo = this.state.filtro;
        filtroNovo.dataInicial = filtroTela.dataInicial;
        filtroNovo.dataFinal = filtroTela.dataFinal;

        this.handleCloseFiltro();

        this.props.getContagemGraficoList(this.state.filtro)
            .then((result) => {

            });
    }

    componentWillMount() {
        this.props.getSummary();
        this.valuesDashboard();
    }

    componentWillUnmount() {
        clearInterval(this.intervalValorDashboard);
    }

    handleCloseFiltro() {
        this.setState({
            showFiltro: false
        });
    }

    handleShowFiltro() {
        this.setState({
            showFiltro: true
        });
    }

    onChangeDataInicial(event) {
        let filtroAux = this.state.filtroAux;
        filtroAux.dataInicial = event.target.value;
        this.setState({ filtroAux: filtroAux });
    }

    onChangeDataFinal(event) {
        let filtroAux = this.state.filtroAux;
        filtroAux.dataFinal = event.target.value;
        this.setState({ filtroAux: filtroAux });
    }

    limparFiltro() {
        let filtroNovo = this.state.filtro;
        filtroNovo.dataInicial = moment().format('YYYY-MM-DD');
        filtroNovo.dataFinal = moment().format('YYYY-MM-DD');
        this.setState({ filtro: filtroNovo });

        let filtroNovoAux = this.state.filtroAux;
        filtroNovoAux.dataInicial = moment().format('YYYY-MM-DD');
        filtroNovoAux.dataFinal = moment().format('YYYY-MM-DD');
        this.setState({ filtroAux: filtroNovoAux });

        this.props.getContagemGraficoList(this.state.filtro)
            .then((result) => {

            });
    }

    changeControlador() {
        this.props.getContagemGraficoList(this.state.filtro);
    }

    valuesDashboard() {
        this.intervalValorDashboard = setInterval(() => {
            try {
                if (this.state.loop === 0) {
                    this.setState({ loop: 1 });

                    this.props.getDadosDashboard()
                        .then((result) => {
                            if (!result.error) {
                                this.setState({
                                    totalControladores: parseInt(result.payload.data.total_controladores),
                                    totalVeiculos: parseInt(result.payload.data.total_veiculos),
                                    totalAlertas: parseInt(result.payload.data.total_alertas)
                                });
                            } else {
                                toastr.error('Erro', 'Falha ao carregar dashboard.');
                            }
                            this.setState({ loop: 0 });
                        });
                }
            } catch (error) {

            }
        }, 10000);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Central de Controle' />
                <Content>
                    <Row>
                        <ValueBox cols='12 4' color='blue' icon='server' /*value="15" text='Total de Controladores' />*/
                            value={this.state.totalControladores} text='Total de Controladores' />
                        <ValueBox cols='12 4' color='green' icon='automobile'
                            value={this.state.totalVeiculos} text='Total de Veículos' />
                        <ValueBox cols='12 4' color='yellow' icon='warning'
                            value={this.state.totalAlertas} text='Total de Alertas' />
                    </Row>

                    <div>
                        <Modal show={this.state.showFiltro} onHide={this.handleCloseFiltro} dialogClassName='modal-dialog'>
                            <Modal.Header className='modal-header' closeButton>
                                <Modal.Title>Filtros dos Gráficos</Modal.Title>
                            </Modal.Header>

                            <Modal.Body className='modal-body'>
                                <LabelAndInputChange
                                    name='data_inicial'
                                    className='modal-campo-data'
                                    type='date'
                                    label='Data Término Inicial'
                                    cols='12'
                                    value={this.state.filtroAux.dataInicial}
                                    onChange={this.onChangeDataInicial} />

                                <LabelAndInputChange
                                    name='data_final'
                                    className='modal-campo-data'
                                    type='date'
                                    label='Data Término Final'
                                    cols='12'
                                    value={this.state.filtroAux.dataFinal}
                                    onChange={this.onChangeDataFinal} />
                            </Modal.Body>

                            <Modal.Footer className='modal-header'>
                                <Button type='button' className='primary' icon='check' label='Salvar' onClick={this.aplicarFiltro} />
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <Row>
                        <Panel bsStyle="success">
                            <Panel.Heading className='panel_heading'>
                                <Panel.Title componentClass="h3">
                                    Contagem volumétrica
                                    <div className='contentHeaderFilter'>
                                        <SelectConfGrupoFisico onChange={this.changeControlador} />
                                        <SelectConfControlador onChange={this.changeControlador} anel={false}/>
                                        <Button type='button' className='primary btn-sm pull-right btn_label_size' icon='filter' label='Filtro Gráfico' onClick={this.handleShowFiltro} />
                                        <Button type='button' className='success btn-sm pull-right btn_label_size' icon='eraser' label='Limpar Filtro' onClick={this.limparFiltro} />
                                    </div>
                                    
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <GraficoContagemControlador
                                    filtrosGrafico={this.state.filtro} />
                            </Panel.Body>
                        </Panel>
                    </Row>

                    <Row>
                        <Panel bsStyle="success">
                            <Panel.Heading className='panel_heading'>
                                <Panel.Title componentClass="h3">Contagem volumétrica por laço</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <GraficoContagemLaco
                                    filtrosGrafico={this.state.filtro} />
                            </Panel.Body>
                        </Panel>
                    </Row>
                </Content>
            </div>
        )
    }
}

const mapStateToProps = state => ({ summary: state.dashboard.summary })
const mapDispatchToProps = dispatch => bindActionCreators({ getSummary, getDadosDashboard, getContagemGraficoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)