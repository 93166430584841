import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import { init, orderRegistros  } from './grupoFisicoActions';
import Button from '../common/form/button';

class GrupoFisicoFormSortable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      registros: this.props.list,
      draggedItemIndex: null, 
      hoveredItemIndex: null,  
    };
  }

  // Atualiza o estado local quando as props mudam
  componentDidUpdate(prevProps) {
    if (prevProps.list !== this.props.list) {
      this.setState({ registros: this.props.list });
    }
  }

  //Armazena o item que está sendo arrastado.
  handleDragStart = (index) => {
    this.setState({ draggedItemIndex: index });
  };

  //Realiza o drop.
  handleDragOver = (event) => {
    event.preventDefault();
  };

  //Armazena o item no qual está sendo "Sobreescrito". Ou seja, ao passar com o item em cima de outro item será armazenada a posição.
  handleDragEnter = (index) => {
    this.setState({ hoveredItemIndex: index });
  };

  // Reseta o item, ou seja, indica que o item arrastado não está mais sobre outro item.
  handleDragLeave = () => {
    this.setState({ hoveredItemIndex: null });
  };

  //Define a nova posição dos itens após um item ser solto na área dos itens que são arrastáveis.
  handleDrop = (index) => {
    const registros = this.state.registros || [];
    const { draggedItemIndex } = this.state;

    if (draggedItemIndex === null) return;

    const draggedItem = registros[draggedItemIndex];
    const updatedRegistros = registros.filter((_, idx) => idx !== draggedItemIndex);

    updatedRegistros.splice(index, 0, draggedItem);

    this.setState({
      registros: updatedRegistros,
      draggedItemIndex: null,
      hoveredItemIndex: null,
    });
  };

  submitForm = async (e) => {
    e.preventDefault();
    const { orderRegistros } = this.props;
    await orderRegistros(this.state.registros);
  };

  render() {
    const { registros, draggedItemIndex, hoveredItemIndex } = this.state;

    return (
      <form onSubmit={this.submitForm} autoComplete="off">
        <style>
          {
           `
           .dragged-item {
            transform: scale(1.05) !important;
            background-color: #e0e0e0 !important;
            transition: transform 0.3s ease, background-color 0.3s ease !important;
            cursor: grabbing !important;
          }
          
          .hovered-item {
            background-color: #d0e0ff !important;
            transition: background-color 0.2s ease !important;
          }` 
          }
        </style>
        <div className="box-body">
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {registros.map((registro, index) => (
              <li
                key={registro.id}
                draggable
                onDragStart={() => this.handleDragStart(index)}
                onDragOver={this.handleDragOver}
                onDragEnter={() => this.handleDragEnter(index)}
                onDragLeave={this.handleDragLeave}
                onDrop={() => this.handleDrop(index)}
                className={`
                  ${draggedItemIndex === index ? 'dragged-item' : ''}
                  ${hoveredItemIndex === index ? 'hovered-item' : ''}
                `}
                style={{
                  padding: '10px',
                  margin: '5px',
                  backgroundColor: '#f0f0f0',
                  cursor: 'grab',
                  border: draggedItemIndex === index ? '2px solid #000' : '1px solid #ccc',
                  width: '30%',
                  display: 'flex',
                  alignItems: 'center',
                  transition: 'transform 0.9s ease',
                  transform: draggedItemIndex === index ? 'scale(1.05)' : 'scale(1)',
                }}
              >
                <div style={{ marginRight: '10px', fontWeight: 'bold', color: '#333' }}>
                  {registro.id}
                </div>
                <span>{registro.descricao}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="box-footer">
          <Button type="submit" className={this.props.submitClass} icon="check" label={this.props.submitLabel} />
          <Button type="button" className="default" icon="close" label="Cancelar" onClick={this.props.init} />
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  list: state.grupoFisicoCad.list
});

GrupoFisicoFormSortable = reduxForm({ form: 'GrupoFisicoFormSortable', destroyOnUnmount: false })(GrupoFisicoFormSortable);
const mapDispatchToProps = dispatch => bindActionCreators({ init, orderRegistros }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(GrupoFisicoFormSortable);
