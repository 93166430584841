import React from 'react';
import { toastr } from 'react-redux-toastr';

export function calculaCicloMaximo(data) {
    let somaCiclo = 0.0;
    for (let index = 0; index < data.length; index++) {
        if (data[index].descricao === 'TpN') {
            for (const key in data[index]) {
                let coluna = key.split('_');
                if (coluna[0] === 'col') {
                    somaCiclo += parseFloat(data[index][key]);
                }
            }
        }
    }
    // console.log((somaCiclo * 0.2) + somaCiclo);


    somaCiclo = Math.ceil((somaCiclo * 0.2) + somaCiclo);

    return somaCiclo;
}

export function getValorCicloMaximo(data) {
    let somaCiclo = 0.0;
    somaCiclo = calculaCicloMaximo(data);

    return somaCiclo;
}

export function validaTempoMinimo(data, column_id, valor) {

    let valorMin = 0.0;
    let descCor = '';
    let descFase = '';
    let totalFases = 0;
    let keyTpn = null;
    let fase = 1;
    let colAtual = +column_id.match(/\d+$/)[0];

    let getCor = ((val) => {
        switch (parseInt(val)) {
            case 1: return 'Vermelho';
            case 2: return 'Amarelo';
            case 3: return 'Verde';
            case 4: return 'Amarelo';
            case 5: return 'Amarelo';
        }
    });

    while (keyTpn === null) {
        if (data[totalFases + 1].descricao === 'TpN') {
            keyTpn = totalFases + 1;
        } else {
            totalFases++;
        }
    }

    while (fase <= totalFases) {
        descFase = data[fase].descricao;
        descCor  = getCor(data[fase][column_id]);
        valorMin = data[fase][`tempo_${descCor.toLowerCase()}_min`];

        let colAnterior  = 1,
            colPosterior = 1;

        while (colAnterior + colPosterior > 0) {

            if (colAnterior > 0 && getCor(data[fase][`col_${colAtual - colAnterior}`]) === descCor) {
                valorMin -= data[keyTpn][`col_${colAtual - colAnterior}`];
                colAnterior++;
            } else {
                colAnterior = 0;
            }

            if (colPosterior > 0 && getCor(data[fase][`col_${colAtual + colPosterior}`]) === descCor) {
                valorMin -= data[keyTpn][`col_${colAtual + colPosterior}`];
                colPosterior++;
            } else {
                colPosterior = 0;
            }
        }

        if (parseFloat(valor) < parseFloat(valorMin)) {
            break;
        }

        fase++;
    }

    const toastrOptions = {
        component: () => (
            <div>
                <div>Tempo informado é menor que o tempo mínimo configurado no controlador.</div>
                <div>"{descCor} {descFase} mínimo: {parseFloat(valorMin).toFixed(1)}"</div>
            </div>
        )
    };


    if (parseFloat(valor) < parseFloat(valorMin) && Number(data[fase][column_id]) !== 5) {
        toastr.warning('Atenção', toastrOptions);
        return parseFloat(valorMin).toFixed(1);
    }
    return parseFloat(valor).toFixed(1);
}

export function validaTempoSecundario(data, column_id, valor) {

    const toastrOptions = {

        component: () => (
            <div>
                <div>Tempo máximo permitido para "Secundário" e "Alternado" é de "19.9"</div>
            </div>
        )
    };

    const toastrOptionsPA = {

        component: () => (
            <div>
                <div>Tempo máximo permitido para "Principal" e "Atuado" é de "299"</div>
            </div>
        )
    };

    if (['2', '4'].indexOf(data[0][column_id]) >= 0) {
        if (parseFloat(valor) <= parseFloat('19,9')) {
            return parseFloat(valor).toFixed(1);
        } else {
            toastr.warning('Atenção', toastrOptions);
            return parseFloat(19.9).toFixed(1);
        }
    } else {// se for P ou A não pode aceitar decimal
        if (parseFloat(valor) <= parseFloat('299')) {
            return parseFloat(valor).toFixed(0);
        } else {
            toastr.warning('Atenção', toastrOptionsPA);
            return parseFloat(299).toFixed(0);
        }
    }

}

export function validaTempoIntervalo(data, column_id, valor) {

    let valorResult = valor || '0';
    valorResult = valorResult.replace(',', '.');
    valorResult = this.validaTempoMinimo(data, column_id, valorResult);
    valorResult = this.validaTempoSecundario(data, column_id, valorResult);

    return valorResult;
}

function validaDetectorObrigatorio(data) {

    let intervaloND = true;

    const toastrOptions = {

        component: () => (
            <div>
                <div>Esse plano exige que seja informado um detector(ND) para cada modo "Atuado, Principal Variável ou Atuado Variável"</div>
            </div>
        )
    };

    for (const col in data[0]) {
        if ((col.indexOf('col_') === 0) && ([3,5,6].indexOf(parseInt(data[0][col])) >=0 )) {
            for (const line in data) {
                if (data[line].descricao === 'ND') {
                    window.$(`.class_nd_${col}`).css('border', 'solid #cccccc 2px');
                    if (parseInt(data[line][col]) <= 0) {
                        window.$(`.class_nd_${col}`).css('border', 'solid #FF7F00 2px');
                        toastr.error('Erro', toastrOptions);
                        intervaloND = false;
                    }

                }
            }
        }
    }

    return intervaloND;

}

export function validaRegrasIntervalo(data) {

    if (validaDetectorObrigatorio(data) && (validaTempoZerado(data) && (validaIntervaloFase(data)))) {
        return true;
    }

    return false;
}

function Conflitante(faseList, fase1, fase2) {

    let fase1_cod = fase1.replace('F', '');
    let fase2_cod = fase2.replace('F', '');

    // console.log('fase1_cod', fase1_cod);
    // console.log('fase2_cod', fase2_cod);

    for (const key in faseList) {
        // console.log('faseList[key].fase_id1',faseList[key].fase_id1);

        if ((faseList[key].fase_id1 == fase1_cod) && (faseList[key].fase_id2 == fase2_cod)) {
            // console.log('fase1 = [', fase1_cod, ']   fase1 =[', fase2_cod, '] conflitante => ', faseList[key].conflitante == 1);
            return faseList[key].conflitante == 1;
        }
    }

    return false;

}

export function validaTempoZerado(data) {
    let tpnValido = true;

    const toastrOptions = {

        component: () => (
            <div>
                <div>Não é permitido Salvar sem informar todos os tempos(TpN) dos intervalos.</div>
            </div>
        )
    };

    for (const idx in data) {
        if (data[idx]['descricao'] === 'TpN') {
            for (const key in data[idx]) {
                if (key.indexOf('col_') >= 0) {
                    window.$(`.class_tpn_${key}`).css('border', 'solid #cccccc 2px');
                    window.$(`.class_tpn_${key}`).css('font-weight', 'normal');
                    window.$(`.class_tpn_${key}`).css('color', '#000000')
                    if (parseFloat(data[idx][key]) <= 0) {

                        window.$(`.class_tpn_${key}`).css('border', 'solid #FF7F00 2px');
                        window.$(`.class_tpn_${key}`).css('font-weight', 'bold');
                        window.$(`.class_tpn_${key}`).css('color', '#FF0000');

                        toastr.error('Erro', toastrOptions);
                        // se for zero em qualquer momento, fica falso
                        tpnValido = false;
                    }
                }
            }
        }
    }
    return tpnValido;
}

export function validaIntervaloFase(data) {
    let intervaloFase = true;
    let validacaoVerde = 2;

    const toastrOptionsMod = {

        component: () => (
            <div>
                <div>Não é possível continuar pois existem "Mod" "A" e "SA" para um plano que não é "Atuado".</div>
                <div>Altere o "Mod" para "P" ou "S".</div>
            </div>
        )
    };

    const toastrOptionsFVerde = {

        component: () => (
            <div>
                <div>Não é possível continuar pois existem intervalos "P" ou "S" sem "amarelo" ou "Vermelho Piscante" depois de um "verde".</div>
            </div>
        )
    };

    const toastrOptionsFPiscante = {

        component: () => (
            <div>
                <div>As cores antes e depois de "Amarelo Piscante" ou "Verde Piscante" devem ser iguais.</div>
            </div>
        )
    };

    for (const idx in data) {
        // valida o modo operação se está de acordo com os P, S, A e SA
        for (const key in data[idx]) {
            if (data[idx]['descricao'] === 'Mod') {

                if (key.indexOf('col_') >= 0) {
                    window.$(`.class_mod_${key}`).css('border', 'solid #cccccc 2px');
                    // se o mod for A ou SA e o Modo Operação foi alterado para Normal
                    if (([3, 4].indexOf(parseInt(data[idx][key])) >= 0) && ([0, 3].indexOf(parseInt(data[0].modo_operacao_id)) >= 0)) {
                        window.$(`.class_mod_${key}`).css('border', 'solid #FF7F00 2px');
                        toastr.error('Erro', toastrOptionsMod);
                        intervaloFase = false;
                    }
                }

            } else {
                if (data[idx]['descricao'][0] === 'F') {
                    // window.$(`.class_f_${key}`).css('border', 'solid #cccccc 1px');
                    validacaoVerde = validaVerdeAmarelo(data, key, data[idx]['descricao']);
                    if ([3, 4].indexOf(validacaoVerde) >= 0) {// se encontrar algum verde a esquerda na mesma linha
                        // if ([2, 4].indexOf(parseInt(data[idx][key])) < 0) {// se for diferente de amarelo ou vermelho piscante
                        if (parseInt(data[idx][key]) === 1) {// se for vermelho, bloqueia
                            window.$(`.class_f_${data[idx]['descricao']}_${key}`).css('border', 'solid blue 3px');
                            toastr.error('Erro', toastrOptionsFVerde);
                            intervaloFase = false;
                        }
                    }

                    // essa validação é responsável por não permitir que salvar alterações na troca de planos quando houver algum verde à esquerda de um verde piscante
                    // e não houver o mesmo verde na direita do verde piscante
                    // verde - verde piscante - vermelho
                    // nesse caso o sistema gera o erro "As cores antes e depois de "Amarelo Piscante" ou "Verde Piscante" devem ser iguais."
                    // foi solicitada a remoção no dia 19/08/2024 pelo Gean (suporte), pois no controlador fisico essa ação é possível

                    // if ([5, 6].indexOf(parseInt(data[idx][key])) >= 0) {
                    //     let colId = key.split('_')[1];
                    //     window.$(`.class_f_${data[idx]['descricao']}_${`col_${parseInt(colId) - 1}`}`).css('border', 'solid #cccccc 1px');
                    //     // window.$(`.class_f_${data[idx]['descricao']}_${key}`).css('border', 'solid #cccccc 1px');
                    //     window.$(`.class_f_${data[idx]['descricao']}_${`col_${parseInt(colId) + 1}`}`).css('border', 'solid #cccccc 1px');

                    //     let hasPrev = data[idx].hasOwnProperty(`col_${parseInt(colId) - 1}`),
                    //         hasNext = data[idx].hasOwnProperty(`col_${parseInt(colId) + 1}`);

                    //     if (hasPrev && hasNext && data[idx][`col_${parseInt(colId) - 1}`] != data[idx][`col_${parseInt(colId) + 1}`]) {

                    //         if ((parseInt(data[0][`col_${parseInt(colId) - 1}`]) !== 4) && (parseInt(data[0][`col_${parseInt(colId) + 1}`]) !== 4)) {
                    //             window.$(`.class_f_${data[idx]['descricao']}_${`col_${parseInt(colId) - 1}`}`).css('border', 'solid blue 3px');
                    //             window.$(`.class_f_${data[idx]['descricao']}_${`col_${parseInt(colId) + 1}`}`).css('border', 'solid blue 3px');
                    //             toastr.error('Erro', toastrOptionsFPiscante);
                    //             intervaloFase = false;
                    //         }
                    //     }
                    // }
                }
            }
        }

    }

    return intervaloFase;
}

export function validaVerdeAmarelo(data, column_id, column_descricao) {

    // retorna
    // 0 -> não pode nada
    // 1 -> pode amarelo
    // 2 -> pode tudo
    // 3 -> pode amarelo e não pode vermelho
    // 4 -> pode tudo menos vermelho

    let colId = 0;
    let verdeAmareloAntes = false;
    let verdeAmareloDepois = false;
    let naoPodeVermelho = false;
    let mesmaLinhaAntes = true;
    let mesmaLinhaDepois = true;

    if (column_id != null) {
        // colId = column_id.split('_')[1];
        // for (let idx = colId; idx < data.length; idx++) {
        for (const idx in data) {
            if (data[idx]['descricao'][0] === 'F') {
                // valida conflito
                colId = column_id.split('_')[1];
                if (Conflitante(data[0].fase_conflitante, column_descricao, data[idx]['descricao'])) {// só faz a validação se for conflitante

                    if (['2', '3', '4'].indexOf(data[idx][column_id]) >= 0) { // se tiver verde ou amarelo na mema coluna
                        if (data[idx]['descricao'] !== column_descricao) {
                            return 0;
                        }
                    }
                    // console.log('aaaaaa',data[idx][`col_${parseInt(colId) - 1}`]);


                    if (['3'].indexOf(data[idx][`col_${parseInt(colId) - 1}`]) >= 0) { // se tiver verde na coluna anterior
                        verdeAmareloAntes = true;
                        if (data[idx]['descricao'] !== column_descricao) {
                            mesmaLinhaAntes = false;
                        }
                    }

                    // segunda parte
                    if (parseInt(colId) === parseInt(data[0].numero_intervalo)) {// se for a ultima coluna, então tem que validar como proximo a primeira coluna
                        if (['3'].indexOf(data[idx][`col_1`]) >= 0) { // se tiver verde na coluna 1
                            verdeAmareloDepois = true;
                            if (data[idx]['descricao'] !== column_descricao) {
                                mesmaLinhaDepois = false;
                            }
                        }

                    } else {
                        if (column_id === 'col_1') {// se for a pripeira coluna, então tem que validar como anterior a ultima coluna
                            if (['3'].indexOf(data[idx][`col_${data[0].numero_intervalo}`]) >= 0) { // se tiver verdena ultima coluna
                                verdeAmareloAntes = true;
                                if (data[idx]['descricao'] !== column_descricao) {
                                    mesmaLinhaAntes = false;
                                }
                            } else {
                                if (['3'].indexOf(data[idx][`col_${parseInt(colId) + 1}`]) >= 0) { // se tiver verde na coluna posterior
                                    verdeAmareloDepois = true;
                                    if (data[idx]['descricao'] !== column_descricao) {
                                        mesmaLinhaDepois = false;
                                    }
                                }
                            }

                        } else {
                            if (['3'].indexOf(data[idx][`col_${parseInt(colId) + 1}`]) >= 0) { // se tiver verde na coluna posterior
                                verdeAmareloDepois = true;
                                if (data[idx]['descricao'] !== column_descricao) {
                                    mesmaLinhaDepois = false;
                                }
                            }
                        }
                    }
                }

                // valida amarelo e ou vermelho piscante depois de um verde. Indiferente de ser conflitante ou não
                if ((['3'].indexOf(data[idx][`col_${parseInt(colId) - 1}`]) >= 0) && (['3'].indexOf(data[idx][`col_${parseInt(colId)}`]) < 0)) { // se tiver verde na coluna anterior, e a coluna atual não for verde
                    verdeAmareloAntes = true;
                    if (data[idx]['descricao'] === column_descricao) {// se estiver na mesma linha

                        if ((parseInt(data[0][`col_${parseInt(colId) - 1}`]) === 4) && (parseInt(data[0][`col_${colId}`]) === 2)) {// se o anterior for SA e o atual for S, pode verde com vermelho
                            naoPodeVermelho = false; // pode ser vermelho
                        } else {
                            naoPodeVermelho = true; // não pode ser vermelho
                        }
                        // if ([1, 2].indexOf(parseInt(data[0][`col_${parseInt(colId) - 1}`])) >= 0) {// se for P ou S
                        //     naoPodeVermelho = true; // não pode ser vermelho
                        // }
                    }
                }

            }
        }

        if (verdeAmareloAntes && verdeAmareloDepois) {// se tiver verde ou amarelo antes e depois
            if (mesmaLinhaAntes && mesmaLinhaDepois) {// se os dois estiverem na mesma linha pode ser verde ou amarelo o valor atual
                if (naoPodeVermelho) {// se não pode ter um vermelho
                    return 3;
                }
                return 2;
            }// se um dos dois estiver em outro linha, o valor atual não pode ser verde ou amarelo
            if (mesmaLinhaAntes && naoPodeVermelho) {// se for na mesma linha antes, e for verde em outra linha, se não pode ter vermelho
                return 4
            }
            return 1;
        } else {
            if (verdeAmareloAntes || verdeAmareloDepois) {// se tiver verde ou amarelo antes ou depois
                if (mesmaLinhaAntes && mesmaLinhaDepois) {// se os dois estiverem na mesma linha pode ser verde ou amarelo o valor atual
                    if (verdeAmareloAntes && naoPodeVermelho) {// se o verde for antes e não ter um vermelhor
                        return 3;
                    }
                    return 2;
                }// se um dos dois estiver em outro linha, o valor atual não pode ser verde ou amarelo
                if (verdeAmareloAntes && mesmaLinhaAntes && naoPodeVermelho) { //se for verde na mesma linha antes e não for verde depois, se não pode vermelho
                    return 4
                }
                return 1;
            }
        }
    }

    return 2;
}

const numImpar = (num) => { return num % 2 !== 0; }

export function somaCicloTempoTotal(data) {

    let tempoTotal = 0.0;
    let arraySA = [];
    let arraySL = [];
    let ativaSL = false;

    // carrega array para seprar o que é mod SA para não somar
    for (const key in data[0]) {
        if (data[0].hasOwnProperty(key)) {
            if ((parseInt(data[0][key]) === 4) && (key.indexOf('col_') >= 0)) {
                arraySA.push(key);
            }
        }
    }

    // carrega array para seprar o que é SL impar para não somar
    for (const idx in data) {
        if (data[idx]['descricao'] === 'SL') {
            let dataSL = data[idx];
            for (const key in dataSL) {
                if (dataSL.hasOwnProperty(key)) {

                    if (key.indexOf('col_') >= 0) {

                        if ((dataSL[key] > 0)) {
                            ativaSL = numImpar(parseInt(dataSL[key]));
                        }

                        if (ativaSL) {
                            arraySL.push(key);
                        }
                    }
                }
            }
        }
    }

    for (const idx in data) {

        if (data[idx]['descricao'] === 'TpN') {
            let dataTpn = data[idx];

            for (const key in dataTpn) {
                if (dataTpn.hasOwnProperty(key)) {
                    // só soma se não for SA e se não for SL impar
                    if ((key.indexOf('col_') >= 0) && (arraySA.indexOf(key) < 0) && (arraySL.indexOf(key) < 0)) {
                        tempoTotal += parseFloat(dataTpn[key]);
                    }
                }
            }
        }
    }


    tempoTotal = parseFloat(tempoTotal).toFixed(1);

    return tempoTotal.replace(',', '.');

}