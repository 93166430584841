import React, { Component } from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from '../common/grid/gridReactTable';
import { getList } from './erroGeralActions';
import { PAGE_SIZE } from '../common/utils/pageSize';

class ControladorErroGeralList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            pages: null,
            loading: true,
            grupoId: '',
            controladorId: ''
        };

        this.fetchData = this.fetchData.bind(this);
        this.handleSetGrupo = this.handleSetGrupo.bind(this);
        this.handleSetControlador = this.handleSetControlador.bind(this);
        this.handleChangeTime = this.handleChangeTime.bind(this);
    }

    fetchData(state) {
        this.setState({ loading: true });

        this.props.getList(
          state.page,
          state.sorted,
          state.filtered
        ).then(() => {
            this.setState({
                loading: false
            });
        });
    }

    timeFormat(time) {
        let timeFormatted = time.replace(/\D/g, '');
        let hour = timeFormatted.substring(0, 2);
        let minute = timeFormatted.substring(2, 4);
        let second = timeFormatted.substring(4, 6);
        hour = hour > 23 ? '23' : hour;
        minute = minute > 59 ? '59' : minute;
        second = second > 59 ? '59' : second;
        return `${hour}${minute ? ':' + minute : ''}${second ? ':' + second : ''}`;
    }

    handleChangeTime(value, onChange) {
        const time = this.timeFormat(value);
        onChange(time);
    }

    handleSetGrupo(value) {
        this.setState({ grupoId: value });
    }

    handleSetControlador(value) {
        this.setState({ controladorId: value });
    }

    render() {

        moment.locale('pt-br');
        const { data, total, grupos, controladores, ips } = this.props;
        const pages = Math.ceil(total / PAGE_SIZE);
        const columns = [
            {
                Header: 'Data',
                accessor: 'data_erro',
                width: 150,
                filterable: true,
                resizable: true,
                className: 'column_grid column_center',
                Cell: ({ value }) => (moment(value).format('DD/MM/YYYY')),
                Filter: ({ filter, onChange }) => (
                    <input  
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        type='date'
                        className='form-control'
                    />
                )
            },
            {
                Header: 'Hora',
                accessor: 'hora_erro',
                width: 120,
                className: 'column_grid column_center',
                filterable: true,
                resizable: true,
                Filter: ({ filter, onChange }) => (
                    <input  
                        onChange={event => this.handleChangeTime(event.target.value, onChange)}
                        value={filter ? filter.value : ''}
                        type='text'
                        maxLength={8}
                        placeholder='hh:mm:ss'
                        className='form-control'
                    />
                )
            },
            {
                Header: 'Grupo',
                accessor: 'grupo_fisico_id',
                width: 180,
                filterable: true,
                resizable: true,
                className: 'column_grid column_center',
                Filter: ({ filter, onChange }) => {
                    const value = filter ? filter.value : '';
                    return (
                        <select
                            class={'form-control'}
                            onChange={event => onChange(event.target.value)}
                            value={value}
                        >
                            <option value="">Todos</option>
                            {grupos && grupos.map((grupo) => (
                                <option key={grupo} value={grupo}>{grupo}</option>
                            ))}
                        </select>
                    )
                }
            },
            {
                Header: 'Controlador',
                accessor: 'numero_controlador',
                width: 120,
                filterable: true,
                resizable: true,
                className: 'column_grid column_center',
                Filter: ({ filter, onChange }) => {
                    const value = filter ? filter.value : '';
                    return (
                        <select
                            class={'form-control'}
                            onChange={event => onChange(event.target.value)}
                            value={value}
                        >
                            <option value="">Todos</option>
                            {controladores && controladores.map((controlador) => (
                                <option key={controlador} value={controlador}>{controlador}</option>
                            ))}
                        </select>
                    )
                }
            },
            {
                Header: 'IP/Porta',
                accessor: 'ip_porta',
                width: 150,
                filterable: true,
                resizable: true,
                className: 'column_grid column_center',
                Filter: ({ filter, onChange }) => {
                    const value = filter ? filter.value : '';
                    return (
                        <select
                            class={'form-control'}
                            onChange={event => onChange(event.target.value)}
                            value={value}
                        >
                            <option value="">Todos</option>
                            {ips && ips.map((ip) => (
                                <option key={ip} value={ip}>{ip}</option>
                            ))}
                        </select>
                    )
                }
            },
            {
                Header: 'Mensagem',
                accessor: 'mensagem',
                className: 'column_grid',
                filterable: true,
                resizable: true,
            }
        ];

        return (
            <ReactTable
                manual
                data={data}
                columns={columns}
                pages={pages}
                onFetchData={this.fetchData}
                loading={this.state.loading}
                filterable
                className='-striped -highlight'
                defaultPageSize={PAGE_SIZE}
                showPaginationBottom={true}
            />
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getList }, dispatch)
export default connect(state => ({
    data: state.controladorErroGeralCad.list,
    total: state.controladorErroGeralCad.total,
    grupos: state.controladorErroGeralCad.grupos,
    controladores: state.controladorErroGeralCad.controladores,
    ips: state.controladorErroGeralCad.ips
}), mapDispatchToProps)(ControladorErroGeralList);
