import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { downloadCroqui, showUpdate, remove, showSAP, getList, alteraMonitora, setPaginaAtual } from './controladorActions'
import SPButton from '../common/form/spButton';
import configLocal from '../configuracao/configLocal';

import ReactTableGT2A from '../common/grid/gridReactTable';
import swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import PermissaoUsuario from '../auth/permissao';

class ControladorList extends Component {

    constructor(props) {
        super(props);

        this.intervalGetList = 0;

        this.removerControlador = this.removerControlador.bind(this);
    }

    handlePageChange = (page) => {
        this.props.setPaginaAtual(page);
    };

    removerControlador(data) {
        swal({
            title: 'Deseja realmente excluir?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {

                this.props.remove(data);

            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.intervalGetList);
    }

    componentDidMount() {
        this.intervalGetList = setInterval(() => {
            this.props.getList();
        }, 5000);
    }

    changeMonitora(row, value) {
        alteraMonitora(row.numero_controlador, value === 'Sim' ? 1 : 0 );
    }

    async downloadCroqui (croqui_file_id) {
        try {
            const response = await this.props.downloadCroqui(croqui_file_id);
    
            if (response) {
                // Cria um URL temporário para o blob retornado pela API
                const url = window.URL.createObjectURL(new Blob([response.payload.data]));
        
                // Cria um link temporário e o clica para iniciar o download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${croqui_file_id}`);
                document.body.appendChild(link);
                link.click();

                // Remove o link temporário após o download
                document.body.removeChild(link);
            } else {
                console.error('Erro ao baixar o arquivo');
            }
        } catch (error) {
            console.error('Erro ao baixar o arquivo:', error);
        }
    }

    render() {
        const contro = this.props.list || [];

        if (contro.length) {

            let listCont = [];
            const grupoFisicoId = configLocal.getConfig('grupo');
            const paginaAtual = this.props.paginaAtual;
            contro.forEach(cont => {
                if (parseInt(grupoFisicoId) === parseInt(cont.grupo_fisico_id)) {
                    listCont.push(cont);
                }
            });

            const columns = [
                {
                    Header: 'Ações',
                    Cell: row => (
                        <div>
                            <PermissaoUsuario tela='CadControlador' condicao={true}>
                                <SPButton type='button' className='warning btn-sm' icon='pencil-alt' data-title='Alterar' onClick={() => this.props.showUpdate(row.original)} />
                                <SPButton type='button' className='danger btn-sm' icon='trash-alt' data-title='Excluir' onClick={() => this.removerControlador(row.original)} />
                            </PermissaoUsuario>
                            <PermissaoUsuario tela='CadControlador' condicao={false}>
                                <SPButton type='button' className='warning btn-sm' icon='pencil-alt' data-title='Alterar' onClick={() => this.props.showUpdate(row.original)} />
                                <SPButton type='button' className='danger btn-sm' icon='trash-alt' data-title='Excluir' disabled="disabled" />
                            </PermissaoUsuario>

                        </div>
                    ),
                    className: 'column_icon',
                    width: 90,
                    resizable: true,
                },
                {
                    Header: 'Controlador',
                    accessor: 'numero_controlador',
                    width: 90,
                    filterable: true,
                    resizable: true,
                    className: 'column_grid column_center',
                    Filter: () => <div className='rt-th'><span >Filtrar</span></div>
                },
                {
                    Header: 'Anéis',
                    accessor: 'total_anel',
                    width: 45,
                    className: 'column_grid column_center',
                    filterable: true,
                    resizable: true,
                },
                {
                    Header: 'Endereço',
                    accessor: 'endereco',
                    className: 'column_grid',
                    filterable: true,
                    resizable: true,
                },
                {
                    Header: 'Cruzamento',
                    accessor: 'cruzamento',
                    className: 'column_grid',
                    filterable: true,
                    resizable: true,
                },
                {
                    Header: 'IP',
                    accessor: 'ip',
                    width: 100,
                    className: 'column_grid',
                    filterable: true,
                    resizable: true,
                },
                {
                    Header: 'Porta',
                    accessor: 'porta',
                    width: 50,
                    className: 'column_grid column_center',
                    filterable: true,
                    resizable: true,
                },
                {
                    Header: 'Croqui',
                    accessor: 'croqui_file_id',
                    width: 60,
                    className: 'column_grid column_center',
                    filterable: false,
                    resizable: true,
                    Cell: ({ value }) => (
                        value !== null ? (
                            <Button onClick={() => this.downloadCroqui(value)} bsSize="xsmall">
                              <i className={'fa fa-download'}></i>
                            </Button>
                          ) : null
                    )
                },
                {
                    Header: 'Ativo',
                    accessor: 'ativo',
                    width: 90,
                    filterable: true,
                    resizable: true,
                    className: 'column_grid column_center',
                    Cell: ({ value }) => (parseInt(value) === 1 ? "Sim" : "Não"),
                    filterMethod: (filter, row) => {
                        if (filter.value === "Sim") {
                            return parseInt(row[filter.id]) === 1;
                        }
                        if (filter.value === "Não") {
                            return parseInt(row[filter.id]) === 0;
                        }
                        if (filter.value === "Todos") {
                            return true;
                        }

                    },
                    Filter: ({ filter, onChange }) =>
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "Todos"}
                        >
                            <option value="Todos">Todos</option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                        </select>
                },
                {
                    Header: 'Monitorar Conexão',
                    accessor: 'monitora_erro',
                    width: 130,
                    filterable: true,
                    resizable: true,
                    className: 'column_grid column_center',
                    Cell:  ({ value, row }) => {
                        return <select
                            onChange={event => this.changeMonitora(row, event.target.value)}
                            style={{ width: "100%" }}
                            value={parseInt(value) === 1 ? "Sim" : "Não"}
                            data-value={value}
                        >
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                        </select>
                    },
                    filterMethod: (filter, row) => {
                        if (filter.value === "Sim") {
                            return parseInt(row[filter.id]) === 1;
                        }
                        if (filter.value === "Não") {
                            return parseInt(row[filter.id]) === 0;
                        }
                        if (filter.value === "Todos") {
                            return true;
                        }

                    },
                    Filter: ({ filter, onChange }) =>
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "Todos"}
                        >
                            <option value="Todos">Todos</option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                        </select>
                },
                {
                    Header: 'Status Conexão',
                    accessor: 'status_conexao',
                    width: 125,
                    filterable: true,
                    resizable: true,
                    className: 'column_grid column_center',
                    Cell: ({ value, row }) => {
                        if (parseInt(row.monitora_erro) === 0) {
                            return <span style={{ color: 'goldenrod' }}>Indeterminado</span>
                        }
                        return parseInt(value) <= 1 ? <span style={{ color: 'green' }}>Conectado</span> : <span style={{ color: 'red' }}>Sem Conexão</span>
                    },
                    filterMethod: (filter, row) => {
                        if (filter.value === "Todos") {
                            return true;
                        }
                        if (filter.value === "Indeterminado" || parseInt(row.monitora_erro) === 0) {
                            return filter.value === "Indeterminado" && parseInt(row.monitora_erro) === 0;
                        }
                        if (filter.value === "Conectado") {
                            return parseInt(row[filter.id]) <= 1;
                        }
                        if (filter.value === "Sem Conexão") {
                            return parseInt(row[filter.id]) > 1;
                        }

                    },
                    Filter: ({ filter, onChange }) =>
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "Todos"}
                        >
                            <option value="Todos">Todos</option>
                            <option value="Conectado">Conectado</option>
                            <option value="Sem Conexão">Sem Conexão</option>
                            <option value="Indeterminado">Indeterminado</option>
                        </select>
                }

            ];

            return (

                <ReactTableGT2A
                    data={listCont}
                    columns={columns}
                    className='-striped -highlight'
                    defaultPageSize={20}
                    onPageChange={this.handlePageChange}  
                    page={paginaAtual} 
                />
            )
        }

        return (
            <div></div>
        );

    }
}

const mapStateToProps = state => ({ 
    list: state.controladorCad.list,  
    paginaAtual: state.controladorCad.paginaAtual 
})
const mapDispatchToProps = dispatch => bindActionCreators({ downloadCroqui, showUpdate, remove, showSAP, getList, alteraMonitora, setPaginaAtual }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ControladorList)
