import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import Button from '../common/form/button';

import { init, sendForced, sendCancelForced } from './planoForcadoAction';

import SelectAllControlador from '../common/selectdb/selectAllControlador';
import SelectPlano from '../common/selectdb/selectPlano';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndSelectForm from '../common/form/labelAndSelectForm';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import configLocal from '../configuracao/configLocal';
import PermissaoUsuario from '../auth/permissao';
import swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import { getPlanoList, getControladorGrupoList } from '../common/selectdb/selectdbAtions';
import SelectAllGrupoFisico from '../common/selectdb/SelectAllGrupoFisico';

class PlanoForcadoForm extends Component {

    constructor(props) {
        super(props);

        this.componentRef = React.createRef();

        this.state = {
            grupoFisicoId: configLocal.getConfig('grupo'),
            configContId: configLocal.getConfig('cont'),
            contId: configLocal.getConfig('cont'),
            planoId: 0,
            horaIni: 0,
            horaFin: 0,
            show: false
        }
        this.sendForcedPlan = this.sendForcedPlan.bind(this);
        this.sendCancelForcedPlan = this.sendCancelForcedPlan.bind(this);
    }

    sendCancelForcedPlan() {

        this.setState({ show: true });
        this.props.sendCancelForced(this.props.valores)
            .then((result) => {
                if (result.payload.data.success) {
                    this.setState({ show: false });
                    toastr.success('Sucesso', 'Plano forçado desativado com sucesso.');
                } else {
                    this.setState({ show: false });
                }
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', ` Atenção: ${error}`));
                this.setState({ show: false });
            });
    }

    sendForcedPlan() {
        if (this.props.valid) {
            const executaPlanoForcado = async () => {

                await html2canvas(this.componentRef.current)
                .then((canvas) => {
                    canvas.style.display = 'none';
                    let image = canvas.toDataURL("png");
                    this.props.change('imageLog', image);
                });

                this.props.valores.grupoFisicoId = this.state.grupoFisicoId;
                this.setState({ show: true });
                this.props.handleSubmit()
                    .then((result) => {
                        if (result.payload.data.success) {
                            this.setState({ show: false });
                            toastr.success('Sucesso', 'Plano forçado ativado com sucesso.');
                        }
                    })
                    .catch(e => {
                        e.response.data.errors.forEach(error => toastr.error('Erro', `Os seguintes controladores: ${error} estão sem conexão`));
                        this.setState({ show: false });
                    });
            };

            if (parseInt(window.$('#pf_seleciona_controlador').val()) === 0) {
                swal({
                    title: 'Deseja realmente enviar para todos os controladores do Grupo físico?',
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonColor: 'primary',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    allowOutsideClick: 'false'
                }).then((result) => {
                    if (result.value) {
                        executaPlanoForcado();
                    }
                });
            } else {
                executaPlanoForcado();
            }


        }

    }

    render() {

        const { handleSubmit } = this.props;

        const requiredSelect = value => (((value === '0') || (value === undefined)) ? 'Obrigatório' : undefined);
        const requiredTime = value => (((value === undefined)) ? 'Obrigatório' : undefined);

        return (
            <form onSubmit={handleSubmit}>
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    dialogClassName='modal-load'
                    backdrop={false}
                >

                    <Modal.Body className='modal-body modal-load-body'>
                        <div >
                            <div >
                                <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                            </div>
                            <div >
                                <h4><b>Aguarde, processando...</b></h4>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                <div className='box-body'  ref={this.componentRef}>

                    <SelectAllGrupoFisico
                        id='pf_seleciona_grupo_fisico'
                        name='pf_seleciona_grupo_fisico'
                        label='Grupo Físico'
                        cols='12'
                        onChange={(event) => {
                            this.setState({grupoFisicoId: event.target.value});
                            this.props.getControladorGrupoList(event.target.value);
                        }}
                        value={this.state.grupoFisicoId}

                    />

                    <SelectAllControlador
                        id='pf_seleciona_controlador'
                        name='pf_seleciona_controlador'
                        label='Controlador'
                        cols='12'
                        onChange={(event) => {
                            this.props.valores.contId = event.target.value;
                            this.setState({contId: event.target.value});
                            this.props.getPlanoList(event.target.value);
                        }}
                        value={this.state.contId}
                        data-grupofisico-id={this.state.grupoFisicoId}
                        data-anel-id={1}
                    />

                    <SelectPlano
                        id='pf_seleciona_plano'
                        name='pf_seleciona_plano'
                        cols='12'
                        data-controlador-id={this.state.contId}
                        data-plano-piscante={true}
                        validate={requiredSelect}
                    />

                    <Field
                        type='time'
                        step="1"
                        component={LabelAndInput}
                        name='horario_inicial'
                        label='Horário Inicial'
                        validate={requiredTime}
                    />

                    <Field
                        type='time'
                        step="1"
                        component={LabelAndInput}
                        name='horario_final'
                        label='Horário Final'
                        validate={requiredTime}
                    />

                    <Field name='pf_anel' component={LabelAndSelectForm} label='Anel' >
                        <option value='1'>Anel 1</option>
                        <option value='2'>Anel 2</option>
                        <option value='3'>Anel 3</option>
                        <option value='4'>Anel 4</option>
                        <option value='5'>Todos</option>
                    </Field>
                </div>
                <div className='box-footer'>
                    <PermissaoUsuario tela="PlanoForcado" condicao={true}>
                        <Button type='button' className='primary' icon='gavel' label='Ativar Plano Forçado' onClick={this.sendForcedPlan} />
                        <Button type='button' className='warning' icon='ban' label='Desativar Plano Forçado' onClick={this.sendCancelForcedPlan} />
                    </PermissaoUsuario>
                    <PermissaoUsuario tela="PlanoForcado" condicao={false}>
                        <Button type='button' className='primary' icon='gavel' label='Ativar Plano Forçado' disabled='disabled' />
                        <Button type='button' className='warning' icon='ban' label='Desativar Plano Forçado' disabled='disabled' />
                    </PermissaoUsuario>
                    {/* <Button type='button' className='default' icon='close' label='Cancelar' /> */}
                </div>
            </form>
        )
    }
}

PlanoForcadoForm = reduxForm({ form: 'planoForcadoForm', destroyOnUnmount: false })(PlanoForcadoForm);

const mapStateToProps = state => {
    const dados = getFormValues('planoForcadoForm')(state);
    /* não consegui identificar em que momento o valor do campo "pf_seleciona_controlador" é
     * atribuído na propriedade "contId", pois mesmo o uso do setState() no evento
     * change do pf_seleciona_controlador não funciona corretamente
     */
    dados['contId'] = window.$('#pf_seleciona_controlador').val();
    return {valores: dados};
};

const mapDispatchToProps = dispatch => bindActionCreators({ init, sendForced, sendCancelForced, getPlanoList, getControladorGrupoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(PlanoForcadoForm)